@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader_bg {
    background: #000;
    padding: 20px;
    margin-top: 80px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
    margin: 0;
    z-index: 999;
}


.loader {
    color: #ffffff;
    display: inline-block;
    margin: 0;
    text-align: center;
    position: fixed;
    width: 100%;
    top: 40%;
    z-index: 999;
}

.loader p{
    color: #fff;
    font-size: 18px;
    padding: 20px;
    font-weight: 300;
}
body {
  background-color: #fff; 
}

* {
  font-family: "Roboto", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  font-family: "Roboto", sans-serif !important;
}
.BaseSelectPadding0 .MuiFormControl-root {
  margin: 0 !important;
}
.div_backgrndimg-pattern {
  /* background-repeat: repeat-y;
    background-image: url("../components//ComponentOtpCard/Khushu-Pattern-Design_3.png");
    width: 100%; */
  background: transparent
    radial-gradient(
      closest-side at 50% 50%,
      #ffffff 0%,
      #ffffffda 63%,
      #ffffff00 100%
    )
    0% 0% no-repeat padding-box;
}
.Background-Image {
  background-repeat: repeat;
  background-image: url(/static/media/Pattern-Design_3.71e27f57.png);
   height: 100vh !important;

}
.rdw-option-wrapper {
  border: none !important;
}
/* .MuiMenu-list{
  display: grid !important;
} */
.PrivateTabIndicator-colorPrimary-95,
.PrivateTabIndicator-colorPrimary-2480,
.PrivateTabIndicator-colorPrimary-3336,
.PrivateTabIndicator-colorPrimary-4097,
.PrivateTabIndicator-colorPrimary-5023 {
  background-color: #7266fb !important;
}
.PrivateTabIndicator-root-440 {
  background-color: #7266fb !important;
}
.PrivateTabIndicator-colorPrimary-209 {
  background-color: #7266fb !important;
}
.MuiTab-root {
  max-width: 900px !important;
}
.MuiIconButton-root:hover {
  background: none;
}

.secured_text {
  text-align: center;
  color: #a9a9a9;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 35px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-bottom: 40px;
}
.secured_text span {
  font-weight: 600;
}
.secured_text svg {
  position: relative;
  top: 6px;
}

.MuiListItem-divider {
  border-bottom: 1px solid #e8e9e9;
}
.MuiPaper-elevation8 {
  /* box-shadow: 0px 2px 20px #0000000F; */
  box-shadow: 0px 0px 0px 0px, 0px 0px 2px 1px rgba(0, 0, 0, 0.14),
    0px 1px 0px -1px rgba(0, 0, 0, 0.12);
}
.MuiListItem-button:hover {
  background: none;
}
.MuiList-padding {
  padding: 0;
}
.MuiPopover-paper {
  max-height: calc(100% - 32px) !important;
}
.logoIcon {
  justify-self: flex-end;
  margin-left: 135px;
  margin-top: -50px;
}

@font-face {
  font-family: "title";
  src: url(/static/media/Roboto-Medium.58aef543.ttf);
}

.tabsLabel {
  font-family: "title" !important;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.26px;
  color: #4d4d4d;
  text-transform: uppercase;
  font-weight: 200;
}

.backBtn-onboarding-stepper {
  width: 86px;
  height: 36px;
  border: 1px solid #e8e9e9;
  font-family: "title";
  border-radius: 18px;
  position: absolute;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1.26px;
  color: #4d4d4d;
  text-transform: uppercase;
  display: -webkit-flex;
  display: flex;
  padding: 0px;
  -webkit-align-self: center;
          align-self: center;
  margin-top: 100px !important;
  left: 70px !important;
}
.backBtn {
  width: 86px !important;
  height: 36px;
  border: 1px solid #e8e9e9;
  border-radius: 100px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1.26px;
  color: #4d4d4d;
  text-transform: uppercase;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  -webkit-align-self: center;
          align-self: center;
  position: absolute;
  left: 70px;
  margin-top: 30px !important;
}

.footerText {
  text-align: center;
  font-family: "title" !important;
  font-size: 14px;
  letter-spacing: 0.48px;
  color: #818e94;
}
.resend {
  text-align: center;
  font-family: "title";
  font-size: 14px;
  letter-spacing: 0.47px;
  color: #2a82f6;
  cursor: pointer;
  margin-top: 10px;
}

.footnoteparagraph {
  font-family: "title";
  font-size: 14px;
  letter-spacing: 0.47px;
}
.baseBtn {
  font-family: "Roboto" !important;
  font-size: 14px;
  letter-spacing: 1.26px;
  font-weight: 500;
}

.appBarName {
  font-size: 14px;
  font-family: "title";
  letter-spacing: 0.25px;
  color: #4d4d4d;
}
.price {
  font-size: 16px !important;
  font-family: "title" !important;
  letter-spacing: 0.15px;
  color: #4d4d4d !important;
}
.subHeader {
  font-size: 14px;
  font-family: "title";
  letter-spacing: 1.25px;
  color: #818e94;
  text-transform: uppercase;
}

.subHeaderDrawer {
  font-size: 22px;
  font-family: "Roboto" !important;
  font-weight: 300;
  letter-spacing: 1.25px;
  color: #5a5a5a;
  text-transform: uppercase;
}

.btns {
  text-align: center;
  font-size: 14px;
  font-family: "title";

  letter-spacing: 1.25px;
  color: #2490f9;
  text-transform: uppercase;
}

.primary {
  font-size: 14px;
  font-family: "title" !important;
  letter-spacing: 0.13px;
  color: #4d4d4d;
}

.MuiListItem-gutters-124 {
  border-left: transparent 3px solid;
  height: 45px;
}

.MuiListItem-gutters-124 svg {
  opacity: 0.6;
}

.MuiListItem-gutters-124 span {
  opacity: 0.6;
}

.MuiListItem-gutters-124 svg {
  margin: 0;
}

.MuiTab-textColorInherit {
  border: 1px solid #e8e9e9 !important;
  height: 60px;
  background: #fff;
}

.MuiTab-textColorInherit:first-child {
  border: none;
}

.MuiTab-textColorInherit:last-child {
  border: none;
}

.tabsLabel {
  letter-spacing: 1.26px !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  color: #4d4d4d !important;
  text-transform: uppercase;
  font: Medium 14px/28px Roboto;
}

.tab_section {
  border: 1px solid #e8e9e9 !important;
  border-radius: 4px;
  box-shadow: 0px 4px 6px #00000014;
}

.Mui-selected p {
  color: #7266fb !important;
}
.MuiButton-containedSecondary {
  background-color: #05ce78;
  height: 45px;
  padding: 10px 30px !important;
  letter-spacing: 1.26px;
}
/* .img-chip-edit-org >div>div{
  width: 90% !important;
} */
.MuiButton-containedSecondary span {
  text-align: center;
  font: Medium 14px/28px Roboto;
  letter-spacing: 1.26px !important;
  color: #ffffff;
  text-transform: uppercase;
}

/* p.MuiTypography-body1 {
  width: 100%;
  letter-spacing: 0.15px;
  color: #4d4d4d;
} */

/* .MuiTypography-body1 {
  font-family: Arial, Helvetica, sans-serif !important;
} */
@font-face {
  font-family: "light";
  src: url(/static/media/Roboto-Light.88823c20.ttf);
}

.noDonations {
  font-family: "light";
  text-align: center;
  font-size: 24px;
  letter-spacing: 0px;
  color: #818e94;
  font-weight: 100;
}
.noDonationsPara {
  width: 300px;
  font-family: "light";
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #818e94;
  font-weight: 100;
  margin: 0 auto;
  margin-bottom: 20px;
}
/* .stepLabel {
  text-align: center;

  font-size: 14px;
  letter-spacing: 0.47px;
  color: #bcc0c2;
  width: 100px;
} */

.stepperPara {
  text-align: center;
  font-family: "light";
  font-size: 16px;
  letter-spacing: 0px;
  color: #818e94;
  margin-top: -10px;
}
.verifyTitle {
  font-family: "light";
  text-align: center;
  font-size: 28px;
  letter-spacing: 0px;
  color: #4d4d4d;
  font-weight: 300;
}
.signup {
  font-family: "light";
  font-size: 30px;
  font-weight: 200;
  letter-spacing: 0px;
  color: #5a5a5a;
  opacity: 1 !important;
}

.secondary {
  font-size: 12px;
  font-family: '"Roboto", "Helvetica", "Arial", sans-serif';
  letter-spacing: 0px;
  color: #818e94;
  opacity: 0.8;
}

.title_top {
  text-align: center !important;
  font-family: "light";
  font-size: 28px;
  letter-spacing: 0px;
  color: #4d4d4d;
  text-transform: capitalize;
  opacity: 0.8;
  font-weight: 300;
  margin: 0 !important;
  margin-bottom: 30px !important;
}
.title {
  text-align: center !important;
  font-family: "light";
  font-size: 22px;
  letter-spacing: 0px;
  color: #4d4d4d;
  text-transform: capitalize;
  opacity: 0.8;
  font-weight: 300;
}

.sub_title {
  text-align: left;
  font-family: "light";
  font-size: 22px;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 0.8;
  font-weight: 300;
  margin: 0 !important;
}

.para {
  text-align: center;
  letter-spacing: 0px;
  font-family: "light";
  font-size: 20px !important;
  color: #818e94 !important;
  opacity: 1 !important;

  opacity: 0.6;
}

.lockPara {
  text-align: center;
  font-size: 28px;
  color: #4d4d4d;
  font-family: "light";
  letter-spacing: 0.5px;
  margin-bottom: 150px;
}
.otpPara {
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  color: #4d4d4d;
  font-size: 30px;
  font-family: "light";
  margin-top: 30px;
  font-weight: 100;
  opacity: 0.8;
}

.otpParagraph {
  margin: 37px 0 20px 0;
  font-size: 16px;
  color: #818e94;
  font-family: "Roboto", sans-serif;
  opacity: 0.7;
  text-transform: uppercase;
}
@font-face {
  font-family: "caption";
  src: url(/static/media/Roboto-Bold.e07df86c.ttf);
}
.logoTitle {
  font-family: "caption";
  font-size: 20px;
  letter-spacing: 0px;
  color: #3b3b3b;
}

@font-face {
  font-family: "buttons";
  src: url(/static/media/Roboto-Regular.11eabca2.ttf);
}
.mainDivSideBar div div label,
.mainDivSideBar div div div {
  font-family: "buttons";
}
.sidebarHeader {
  font-family: "light";
  font-size: 22px;
}
/* ul li{
  font-family: "buttons";
} */
.head-table {
  font-size: 14px;
  font-family: "buttons";
  letter-spacing: 0.13px;
  color: #818e94;
  text-transform: uppercase;
  margin-left: 12px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 600;
}
.col {
  font-family: "buttons";
}
.action {
  font-size: 14px;
  font-family: "buttons";
  letter-spacing: 0.25px !important;
  color: #2490f9 !important;
  text-transform: uppercase;
  font-weight: 500;

  text-align: center;
}
.invoice {
  color: #2490f9;
  text-decoration: none;
  cursor: pointer;
  font-family: "buttons";
}

.invoice:hover {
  text-decoration: underline;
}

.detail {
  margin-left: 12px;
  font-size: 14px;
  font-family: "buttons";
  font-weight: lighter;
  letter-spacing: 0;
  color: #4d4d4d;
}

.primary {
  font-size: 14px;
  letter-spacing: 0.13px;
  color: #4d4d4d;
  font-family: "buttons";
}

.secondary {
  font-size: 12px;
  letter-spacing: 0.13px;
  color: #818e94;
  font-family: "buttons";
}
.status {
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #818e94 !important;
  padding-top: 0px;
  font-family: "buttons" !important;
}

.MuiTypography-body2 {
  color: #818e94 !important;
  font-size: 0.875rem;
  font-family: "buttons";
  font-weight: 400;
  line-height: 1.43;
}
.selectRemindermargin0 > div > div {
  margin: 0 !important;
}
.verifyPara {
  font-family: "buttons";
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #818e94;
  font-weight: 200;
  opacity: 0.7;
  margin-top: -15px;
  font-family: "light";
  font-size: 20px !important;
  color: #818e94 !important;
}

.footerSText {
  text-align: center;
  font-family: "buttons" !important;
  font-size: 14px;
  letter-spacing: 0.48px;
  color: #818e94;
  letter-spacing: 1px;
}
.passwordCheck {
  font-family: "buttons";
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #818e94;
  margin: 0;
  margin-bottom: 10px;
}
.forgotPassword {
  font-family: "buttons";
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
  letter-spacing: 0.47px;
  color: #818e94;
  cursor: pointer;
  font-weight: 600;
}
.forgotPara {
  font-family: "buttons";
  font-size: 12px;
  letter-spacing: 0.41px;
  line-height: 18px;
}

.inputText {
  font-family: "buttons";
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #818e94;
}

.secured {
  font-family: "buttons" !important;
  font-size: 13px;
  letter-spacing: 0.47px;
  color: #d3d8da;
  padding-left: 3px;
  text-transform: uppercase;
  margin-top: 14px !important;
}
.errorMsg {
  text-align: center;
  font-family: "buttons";
  font-size: 14px;
  letter-spacing: 0.47px;
  color: #b00020;
}
.appBarItems {
  margin-top: 3px;
  font-size: 14px;
  font-family: "buttons" !important;
  letter-spacing: 0.25px;
  color: #2a82f6;
  text-transform: capitalize;
}

.appBarRole {
  font-size: 12px;
  font-family: "buttons";
  letter-spacing: 0.22px;
  color: #818e94;
  text-transform: capitalize;
  margin-top: -15px;
}

.itemText {
  font-size: 14px;
  font-family: "buttons";
  letter-spacing: 0.13px;
  color: #4d4d4d;
}

.label {
  font-family: "buttons";
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #4d4d4d;
  padding-left: 1px;
}

.options {
  font-family: "buttons";
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #4d4d4d;
}

.MuiSelect-select:focus {
  background: none;
}

.border {
  width: 226px;
  height: 0.00048828125px;
  border: 1px solid #e9e9e9;
  opacity: 0.6;
}

.logoImg {
  width: 190px;
  height: 40px;
}

@media (max-width: 500px) {
  .logoIcon {
    justify-self: flex-end;
    margin-left: 40px;
    margin-top: -50px;
  }
  .border {
    width: 130px;
    height: 0.00048828125px;
    border: 1px solid #e9e9e9;
    opacity: 0.6;
  }
  .logoImg {
    margin-left: -20px;
  }
}

@media (max-width: 365px) {
  .logoImg {
    width: 150px;
    height: 30px;
  }
  .logoIcon {
    justify-self: flex-end;
    margin-left: 30px;
    margin-top: -50px;
  }
  .border {
    width: 110px;
    height: 0.00048828125px;
    border: 1px solid #e9e9e9;
    opacity: 0.6;
  }
}
@media (max-width: 320px) {
  .logoImg {
    width: 140px;
    height: 30px;
  }
  .logoIcon {
    justify-self: flex-end;
    margin-left: 20px;
    margin-top: -50px;
    width: 12px;
  }
  .border {
    width: 90px;
    height: 0.00048828125px;
    border: 1px solid #e9e9e9;
    opacity: 0.6;
    margin-left: -10px;
  }
  .appBarName {
    margin-left: -10px;
    font-size: 12px;
  }
  .appBarRole {
    margin-left: -10px;
    font-size: 10px;
  }
  .appBarItems {
    margin-left: -10px;
    font-size: 12px;
  }
}
.MuiTabs-flexContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.MuiTabs-flexContainer .MuiTab-root {
  width: 25%;
}

.MuiTabs-flexContainer .MuiTab-root p {
  font-weight: 600;
  letter-spacing: 1.26px;
}

.MuiChip-deleteIcon-25 {
  color: #4d4d4d;
}

.field_code {
  width: 110px;
}

.button_hlpr_text {
  letter-spacing: 0.47px;
  color: #818e94 !important;
  margin-top: 20px !important;
}

.otpinput input:nth-child(3n) {
  background: #b00020 !important;
}

.main_section {
  box-shadow: 9px -11px 145px -39px #fff;
}

/* .from_sec{
  display: block;
  width: 100% !important;
}


.from_sec div{
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;

}

.show_password{
  position: absolute;
  right: 0;
}

.from_sec .MuiIconButton-edgeEnd-160 {
  margin-right: -12px;
  position: absolute;
  right: 0;
}



.form_header{
  margin: 0 !important;
} */



/* .reset_section{
  display: block !important;
  width: 100% !important;
}

.MuiIconButton-edgeEnd-95 {
 position: absolute;
 right: 0;
}

.MuiIconButton-edgeEnd-188 {
  position: absolute;
  right: 0;
 }  */

.MuiListItem-root-76 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiList-padding-66 {
  padding-top: 0;
  padding-bottom: 0;
}

.MuiList-padding-107 {
  padding-top: 0;
  padding-bottom: 0;
}

.list_section p {
  width: 70%;
}

.MuiTableCell-root {
  padding: 7px 0 7px 15px !important;
}


.MuiTableBody-root .MuiTableCell-body {
  padding: 0px 15px !important;
}

.button_text {
  /* color: #fff !important; */
  font-size: 14px;
  font-weight: 500;
}

.filter .MuiIconButton-label {
  color: #2a82f6 !important;
}

.MuiTabs-indicator {
  background-color: #7266fb !important;
}
.password_container {
  letter-spacing: 0.15px;
  color: #818e94 !important;
}

.MuiFormLabel-root {
  color: #818e94 !important;
}
.from_sec .MuiInput-root:before {
  border-bottom: 1px solid #e3e6e8 !important;
}

.from_sec .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #d3d8da;
  pointer-events: none;
}

.from_sec label {
  color: #818e94;
}

/* input{
  min-width: 100% !important;
} */
.addLinkTag {
  cursor: pointer;
  color: blue !important;
}
.from_sec_password {
  display: block;
  width: 100% !important;
}

.password_container button {
  position: absolute;
  right: 0;
}

.password_container .MuiInput-root:before {
  border-bottom: 1px solid #e3e6e8 !important;
}

.password_container div div::before {
  border-bottom: 1px solid #e3e6e8;
}

.main {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  opacity: 1;
  background-color: white;
  /* margin-left: -20px; */
  border: 1px solid #e8e9e9;
  /* height: 100px; */
  padding-top: 20px;
  padding-bottom: 20px;
}

.block {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-left: 0;
}

.header {
  font-family: "light" !important;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: "#294C5C";
  opacity: 0.8;
  padding-left: 5px;
  margin-bottom: 10;
  padding: 0px !important;
  background: #fff;
  text-align: left !important;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75) !important;
}

.amount {
  text-align: left;
  font-size: 34px !important;
  font-weight: bold !important;
  font-family: "caption" !important;
  letter-spacing: 0;
  color: #54707d;
  opacity: 1;
  margin-top: -10;
  margin-bottom: 0;
}

.percentage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  font: 12px/14px;
  color: #818e94;
}

.text {
  font-size: 12px !important;
  font-family: "light" !important;
  letter-spacing: 0.4px;
  color: #294c5c;
}

.rate {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  opacity: 0.6;
  margin-top: -10px;
  margin-left: -5px !important;
}

.percent {
  text-align: right;
  font-size: 12px/14px;
  letter-spacing: 0.4px;
  color: #05ce78;
}

.negPercent {
  text-align: right;
  font-size: 12px/14px;
  letter-spacing: 0.4px;
  color: #b00020;
}
thead tr th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

tfoot tr td div p {
  font-family: "buttons" !important;
}

.input-fields div div div label,
.input-fields div div div div input {
  font-family: "buttons";
}

.tab_section div div div:last-child {
  background-color: #7266fb !important;
}

ul li {
  font-family: "buttons" !important;
}

.ellipse .odd,
.ellipse .even {
  font-family: "buttons" !important;
  display: inline-block !important;
  padding: 0px !important;
  -webkit-justify-content: end !important;
          justify-content: end !important;
}

.orgForm3 div div div label,
ul li,
.orgForm3 div div div div {
  font-family: "buttons" !important;
}

label {
  font-family: "buttons" !important;
}

.filter button:hover {
  background: none !important;
}

footer button span span {
  font-family: "buttons" !important;
}

.white_bg div:last-child button {
  width: 188px !important;
}

.name_rol_cont span {
  font-family: "buttons" !important;
}

.MuiAlert-message {
  font-family: "buttons" !important;
}

.input-fields span {
  font-family: "buttons" !important;
}

.bold {
  font-family: "caption" !important;
  color: #4d4d4d !important;
}

/* .team-members-table div div div {
  overflow-x: hidden !important;
}

.inquiry-table div div div {
  overflow-x: hidden !important;
} */

.divider-inq {
  padding: 0px !important;
}

/* .ellipse .odd {
  position: relative;
  left: -41px;
  width: 36px;
  z-index: 0;
} */

.from_sec form span {
  font-family: "buttons" !important;
}
.from_sec {
  width: inherit;
}
.bodyNav {
  padding-bottom: 80% !important;
}

.sortableHelper {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  background-color: white;
  /* border-bottom: none; */
}
/* .sortableHelper td {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    background-color: white;
    border-bottom: none;
  } */
.sortable-item {
  cursor: -webkit-grab;
  cursor: grab;
}

.sortable-container > .sortable-item:hover {
  cursor: -webkit-grab;
  cursor: grab;
  /* background-color: black; */
}

body > .sortable-item:hover {
  cursor: -webkit-grab;
  cursor: grab;
}

.footnoteparagraph {
  font-family: "title" !important;
}
.Verification_card {
  font-family: "light" !important;
}


.salah-para {
  font-family: "buttons";
}

aside::-webkit-scrollbar {
  width: 5px;
  height: 18px;
  cursor: pointer;
}
aside::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px #ebebeb !important;
  background-color: #ebebeb !important;
  border-radius: 20px;
  height: 50px !important;
  max-height: 20px;
  background-clip: content-box;
  cursor: pointer;
}
aside::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #fffeee;
  background-color: #fffeee;
  border-radius: 0px;
  margin: 0 30px !important;
  cursor: pointer;
}

/* .footer-saparator{
  height: 349px;
}
.footer-saparator2{
  height: 105px;
} */

aside {
  position: fixed !important;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  scrollbar-width: thin !important;
  scrollbar-color: #ebebeb #fffeee;
}


.pagination-number {
  text-align: center;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3cc480 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #3cc480 !important;
}
.otp-field-wrapper input[type="number"]::-webkit-outer-spin-button,
.otp-field-wrapper input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-field-wrapperinput[type="number"] {
  -moz-appearance: textfield;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #d3d8da !important;
}
.select-drop-down div div div:before {
  border-bottom: 1px solid #d3d8da !important;
}

.DetailTableDropdownCol .select-drop-down div div div:before {
  border-bottom: none !important;
}

.DetailTableDropdownCol .MuiInput-underline:before {
  border-bottom: none !important;
}

.DetailTableDropdownCol .MuiInput-underline:after {
  border-bottom: none !important;
}


.img-chip {
  /* width: 570px !important; */
  width: 100%;
}
.img-chip div div:before,
.img-chip-edit div div:before {
  border-bottom: 1px solid #d3d8da !important;
}
.contact-email div div div:before {
  border-bottom: 1px solid #d3d8da !important;
}
.main-footer p,
.main-footer a {
  font-family: "light" !important;
}

.salahDrawer div > div {
  border: none !important;
}
.isStartDate div:not(.date-time-divider),
.isEndDate div {
  border: none !important;
}
/* .MuiPaper-outlined{
  border: none !important;
} */

.MuiInputBase-root {
  font-family: Arial, Helvetica, sans-serif !important;
}
.MuiTab-root {
  padding: 16px 12px !important;
}
.MuiTab-textColorPrimary {
  color: rgba(0, 0, 0, 0.54) !important;
}

/* .MuiListItem-root{
  width: 100px !important;
}
.MuiPaper-elevation8{
  width: 100px !important;
}
.MuiMenu-list{
  display: grid !important;
} */

.img-chip .MuiButtonBase-root,
.img-chip-edit .MuiButtonBase-root {
  background-color: #e0e0e0 !important;
  border-radius: 16px !important;
  margin: 2px;
}
/* .signup-container div div div div header {
  padding-top: 30px !important;
} */
.side-drawer__title {
  font-family: "light", sans-serif !important;
}

.MuiButton-textPrimary {
  color: #3f51b5 !important;
}

.MuiInput-underline .MuiError:after {
  border-bottom: 2px solid #f44336 !important;
}
.MuiInput-underline:after {
  border-bottom: 1px solid #2a82f6 !important;
}

.MuiInput-underline.Mui-error:after {
  border-bottom: 1px solid #f44336 !important;
}

.MuiList-padding {
  padding-right: 0px;
  
}
 .MuiButton-containedPrimary {
  background-color: #2490f9 !important;
}
.MuiButton-containedPrimary:hover {
  background-color: rgb(25, 100, 174) !important;
} 

.dasheddBorder {
  border-bottom: 1px dashed #d3d8da;
  margin-bottom: 30px;
}

.dasheddBorder_form {
  border-bottom: 1px dashed #d3d8da;
}

.SolidBorder {
  border-bottom: 1px solid #e8e9e9;
}

.buttons {
  text-align: right;
  padding-top: 28px;
}

.btnCencel button {
  background: none !important;
  min-width: inherit !important;
  margin-right: 7px;
}

.btnCencel button:hover {
  background: none !important;
}

.btnCencel button:focus {
  background: none !important;
}

.btnCencel button span {
  color: #4d4d4d !important;
  text-transform: uppercase !important;
}

.btnCencel button span:hover {
  color: #2490f9 !important;
}

.changeBtn button {
  background: none !important;
  padding: 0 !important;
  min-width: 0 !important;
  min-width: initial !important;
  height: auto !important;
}

.changeBtn button:hover {
  background: none !important;
  padding: 0 !important;
}

.changeBtn button:focus {
  background: none !important;
  padding: 0 !important;
}

.changeBtn button span {
  color: #2490f9 !important;
}

.changeBtn button span:hover {
  text-decoration: underline;
}

.MuiButton-contained.Mui-disabled {
  background-color: #e4e8ec !important;
}


.p-text span {
  color: #4d4d4d;
  font-weight: bold;
  font-size: 14px;
}

.MuiInputLabel-shrink {
  -webkit-transform: translate(0, 1.5px) scale(0.85) !important;
          transform: translate(0, 1.5px) scale(0.85) !important;
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.dc-inq-table .ellipse {
  margin-left: 6px !important;
}

.header-nav .makeStyles-sectionDesktop-6 {
  margin-right: 43px !important;
}

.page_body {
  background: #fff;
  border: #e8e9e9 1px solid;
}

.page_body_inner {
  padding: 30px;
}

.jss12 {
  /* border: 1px solid #E8E9E9; */
  height: none !important;
  box-shadow: none !important;
  margin-top: 0;
  margin-left: 5px;
  background-color: transparent !important;
}

.card_section {
  padding-bottom: 30px;
}

.field_box {
  margin-bottom: 0px;
  position: relative;
  cursor: text;
}

.field_box .phone-text-field {
 
}

.image_boxes{
  margin: 10px 0px;
}
.mouse-hover {
  cursor: pointer;
  min-height: 35px;
}
.user_name {
  text-transform: capitalize !important;
}
.field_box .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}
.events-logs-contact-us .accordion {
  width: 100% !important;
}
.events-logs-contact-us .accordion {
  height: auto !important;
}
.border_top_bottm {
  border-top: #d3d8da 1px solid;
  border-bottom: #d3d8da 1px solid;
  padding: 15px 0;
}

.border_top_bottm.form-switch-tooltip .MuiTooltip-tooltipArrow {
  padding: 10px !important;
  color: #ffffff ;
}


.border_top_bottm.form-switch-tooltip .MuiTooltip-popper {
  left: -18px !important;
  top: -10px !important;
}

.border_top_bottm.form-switch-tooltip .MuiTooltip-arrow {
  position: absolute !important;
  left: 50% !important;
  bottom: 1px !important;
  -webkit-transform: translateX(-80%) !important;
  transform: translateX(-80%) !important;
} 


.form_button {
  margin-top: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
}
.MuiDialog-container {
  height: 100%;
  outline: 0;
  top: 0px;
  right: 0px;
}

.editor_box {
  margin-bottom: 0px;
}

.top_border_none {
  border-top: none !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.bottom_border_none {
  border-bottom: none !important;
  margin-top: 0 !important;
  padding-bottom: 0 !important;
}

.top_space_none {
  padding-top: 0 !important;
}

.bottom_space_none {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.checkboxContainerC .MuiFormControlLabel-label {
  margin-left: 0px;
  margin-bottom: 10px;
}

.checkboxContainerC .MuiTypography-root{
  margin-left: 9px;
}

.chip_list {
  width: 100% !important;
  /* min-height:35px; */
}
.donation_card_section_inner {
  width: auto !important;
}
.center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.tableBody .tableScroll .MuiTableHead-root .MuiTableCell-root {
  border-collapse: collapse;
  width: 100%;
}

.PageHeaderWithTabs .MuiTab-textColorPrimary.Mui-selected {
    color: #2a82f6 !important;
}

.orgTitled {
  font-size: 24px;
  letter-spacing: 0px;
  color: #4d4d4d;
  text-transform: capitalize;
  opacity: 0.8;
  font-weight: 300;
  margin: 0 !important;
  height: 60px !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 30px !important;
}

.footer {
  background: #fff;
  z-index: 999;
}

.MuiFormControl-root {
  width: 100% !important;
}

.side-drawer__footer {
  width: 320px !important;
  left: 24px !important;
}
#checkbox {
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
#rc-editable-input-1 {
  /* pointer-events: none; */
}

.des_handle_icon {
  height: 24px;
  width: auto;
  background: #fff;
  margin: 0 8px;
  position: relative;
  top: -5px;
}

.sub_decrition {
  font-size: 14px !important;
  color: #818e94;
  margin-top: 7px !important;
}
.MuiDialog-scrollPaper {
  /* position: none !important; */
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
  margin: 0px auto;
}

.MuiDialog-paperWidthSm {
  top: 30% !important;
}
.side-drawer + div .MuiDialog-scrollPaper{
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #2490F9 !important;
}

.HeaderDevider div div {
  border-bottom:#E8E9E9 1px solid;
}

.HeaderDevider div div div{
  border-bottom:none;
}

.HeaderDevider .filter{
  border-bottom: none;
}

.HeaderDevider .th_arrow_icon{
  border-bottom: none;
}

.HeaderDevider .MuiListItemText-root{
  border-bottom: none;
}

.nav_container2 .Mui-disabled{
  opacity: 1 !important;
}

.inner-form .MuiIconButton-label .text-box {
  color: #4d4d4d !important;
}

.MuiCheckbox-root {
  color: #4D4D4D !important;
}

 .Mui-checked {
  color: #05CE78 !important;
}



.pricing-modal input[type="number"]::-webkit-outer-spin-button,
.pricing-modal input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: auto !important;
}

.inquiry-list-aboutus .MuiPaper-outlined {
  border: 0 !important;
}

.detailsTableContainer{
  margin-top: 64px;
}

.noBorder .MuiPaper-outlined{
  border: none !important;
}

.isStartDate .noHelperText {
  color: #B00020 !important;
  margin-top: 4px;
}

.TextArea .text-editor .editor_tool{
  display: none;
}

.text-editor .editor_tool{
  display: none;
}

.header-nav{
  background: #fff;
}

.prop.col_td{
  width: 160px !important;
}

.bodyNav .a {
  fill: transparent !important;
}

.react-tel-input .form-control{
  width: 100% !important;
}

.footerLower{
  position: fixed;
    bottom: 0;
    width: 268px !important;
}

.footerLower hr{
  position: relative;
  top: -20px;
}


.from_sec .MuiInputBase-input{
  background: none !important;
}

.TimeZoneBody {
  margin-left: 361px;
  position: relative;
}

.makeStyles-FilterIcon-457 {
  z-index: 2;
}
.field_box .base-text-field .MuiInputBase-input{
  background: none !important;
}

.base-text-field .MuiInputBase-input{
  background: none !important;
}

.RevokedMainContainer .middle-section{
  margin-top: 0px;
}

.RevokedMainContainer .footer {
  width: calc(100% - 0px);
  height: 34px;
  position: fixed;
  bottom: 0;
  background: none;
}

.MuiDialogContentText-root {
  margin-bottom: 12px;
  width: auto !important;
  width: initial !important;
}

.DragTable .MuiTableRow-root.sortableHelper .MuiTableCell-body.MuiTableCell-paddingNone, .SimpleTablContainer .MuiTableBody-root .MuiTableCell-body.MuiTableCell-paddingNone:first-child{
  padding-right: 12px !important;
  padding-left: 5px !important;
}

body .inner-form .MuiInputBase-input{
  background: none !important;
}

.CheckoutPaymentForm .MuiInputLabel-shrink{
  display: none;
}

.CheckoutPaymentForm fieldset legend{
  max-width: 0.00px !important;
}

.MuiTableHead-root .MuiTableCell-head {
  text-transform: uppercase;
}
.DetailsHeaderContainer .DtlInfoCol .DtlInfoColSubTitle{
  text-transform: capitalize;
}

.PageHeaderWithTabs .MuiTab-root {
  min-width: 0 !important;
  min-width: initial !important;
}

.ConnectedCopMainContainer .ConnectedAccountTableftContainer{
  -webkit-align-items: center;
          align-items: center;
}

.ConnectedCopMainContainer .MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ConnectedCopMainContainer .MuiAccordion-rounded:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ConnectedCopMainContainer .Mui-expanded{
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}


.MuiTooltip-tooltip{
  background-color: #4D4D4D;
  font-size: 12px !important;
  color: #FFFFFF !important;
  font-weight: 400 !important;
  padding: 10px !important;
}

.input_container .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #F8F8F8 !important;
}

.MuiDialog-paperWidthSm li{
  font-weight: 600;
  color: #4D4D4D;
  display: inline-block;
  margin-right: 10px;
}

.TitleA{
  color: #5A5A5A;
  font-size: 22px;
}

.TitleASub{
  color: #818E94;
  font-size: 14px;
  letter-spacing: 1.25px;
}

.CheckBoxContainer{
  padding-top: 25px;
}

.CheckBoxContainer .MuiFormControlLabel-root{
  width: 100%;
}

.TextEditorWithoutHeader .editor_tool{
  display: none  !important;
}

.TextEditorWithoutHeader .text-editor {
  border: 1px solid #d3d8da;
  border-radius: 4px;
  background: #F7F8FA;
}

.CencelMemberContainer{
  max-width: 1000px;
  margin: 0 auto;
}

.PageheaderCont{
  background: #F7F8FA;
  padding: 32px 0;
}

.PageheaderInner {
  max-width: 1360px;
  margin: 0 auto;
  position: relative;
}

.PageheaderInner h1{
  color: #5A5A5A;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  margin: 0;
}

.PageheaderInner .backBtn {
  margin: 0 !important;
}

.CencelMemberContainer .upperEditorService p {
  color: #868686;
  letter-spacing: 1.5px;
  font-size: 14px;
  margin-bottom: 12px;
}






.status{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    color: #4D4D4D;
    font-size: 14px !important;
    
}


.para2{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    color: #818E94;
    font-size: 14px;
    
}

.cancel{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    color: #4D4D4D;
    font-size: 14px;
    letter-spacing: 1.26px;
    -webkit-align-self: center;
            align-self: center;
    margin-right: 20px;
    cursor: pointer;
}

/* .ellipse{
    margin-left: 13px;
}

.ellipse li img{
    width: 24px;
    height: 24px;
}

.ellipse .odd{
    left: -15px;
} */

tbody tr .MuiTableCell-root-534 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.cat-lower{    
font-size: 12px;
letter-spacing: 0px;
color: #818E94;
}

.log-div div{
    overflow: hidden;
}

.log-div .name{
    width:100%
}


.contact-us-backbutton{
    position: 'relative';
    height: '65px' !important;
}

.backBtn {
  width: 86px !important;
  height: 36px;
  border: 1px solid #e8e9e9;
  border-radius: 100px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1.26px;
  color: #4d4d4d;
  text-transform: uppercase;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  -webkit-align-self: center;
          align-self: center;
  position: absolute;
  left: 70px;
  margin-top: 30px !important;
}

.TextEditor{
    border: #D3D8DA 1px solid;
}

.TextEditor .css-fu1za2 {
    position: relative;
    padding: 5px 11px;
    margin: 0 0px;
    border-bottom: 2px solid #eee;
    margin-bottom: 0px;
    padding-top: 12px;
}

.TextEditor .editor{
    padding: 17px;
    padding-top: 0;
}

.TextEditor p {
    color: #818e94;
    opacity: 1;
    font-size: 16px;
    line-height: 26px;
}

.withoutFooter .cr_d_container{
    padding-bottom: 15px;
}


.DfStripConnectContainer{
    margin-top:120px;
}

.DfStripConnectContainer .DFLogo {
    width: 215px;
    margin: 0 auto;
    display: inherit;
}

.DfStripConnectContainer .connectTitle{
    font-weight: 300;
    font-size:28px;
    color:#4D4D4D;
    text-align: center;
    margin: 0;
}

.DfStripConnectContainer .stripe-para{
    max-width:570px;
    margin: 0 auto;
}

.amountIcon {
    color: #818E94;
    font-size: 16px !important;
    font-weight: 400;
    position: absolute;
    top: 23px;
    left: 0;
}

.amountField .MuiInputBase-input{
    text-indent: 15px;
}

.FormInfoLine{
    color:#818E94;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.FormInfoLine a{
    color:#2A82F6;
    font-size: 12px;
    margin-left: 4px;
    text-decoration: none;
    font-weight: 500;
}

.FormInfoLine svg{
    color:#818E94;
    margin-right: 5px;
}

.addButtonContainer {
    display: -webkit-flex;
    display: flex;
    padding: 3px 7px;
    color: #2490F9;
    width: 135px;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 4px;
    border: #E4E8EC 1px solid;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding-right: 0;
}
.addButtonContainer svg{
    margin-left: 10px;
    font-size: 20px;
}

.addButtonCol {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.DonationMethodFieldContainer{
    max-width: 530px;
}

.DonationMethodFieldContainer .field_box{
    position: relative;
}


.DonationMethodFieldContainer .bottom_text_col .counter_text {
    color: #b0b9c1;
    font-size: 12px;
    font-family: Poppins,sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    float: right;
    margin-top: -37px;
    margin-right: 13px;
}

.FieldDeleteButton {
    position: absolute;
    right: -30px;
    top: 14px;
    color: #9f9f9f;
    cursor: pointer;
}

.addButtonContainerTwo {
    display: -webkit-flex;
    display: flex;
    padding: 0;
    color: #2490F9;
    min-width: 135px;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 4px;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    padding-right: 0;
    margin-top: 20px;
}

.addButtonContainerTwo svg{
    margin-right: 5px;
}

.DonationAccrdionWrapper .MuiAccordionDetails-root {
    display: block;
    padding: 8px 16px 16px;
    width: 60%;
}

.DonationMethodmainContainer .MuiAccordionDetails-root {
    display: block;
    padding: 8px 16px 16px;
    max-width: 700px;
}


.AccordionDetailConainer{
    width: 62%;
    display: -webkit-flex;
    display: flex;
    padding-bottom: 20px;
}

.FormSwitchManContainer {
    border-bottom: #E4E8EC 1px solid;
    padding: 6px 0;
}

.FormSwitchManContainer div{
    margin: 0 !important;
}

.AccordionDetailConainer .MuiFormControl-root{
    margin: 0 !important;
}

.AccordionDetailConainer .MuiNativeSelect-select.MuiNativeSelect-select {
    
    height: 51px;
    padding: 0 13px;
}

.AccordionDetailConainer .MuiInput-underline:before {
    border: 1px solid #d3d8da !important;
    height: 51px;
    border-radius: 4px;
}

.AccordionDetailConainer .MuiInputLabel-formControl {
    top: -9px;
}

.infoText {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.infoText svg{
    margin-right: 8px;
    color:#818E94;
}

.infoText p{
    color:#4D4D4D;
    font-size: 16px;
    margin: 0;
}


@font-face {
  font-family: 'Roboto-Medium';
  src: url(/static/media/Roboto-Medium.58aef543.ttf);
}

@font-face {
  font-family: 'Roboto-Light';
  src: url(/static/media/Roboto-Light.88823c20.ttf);
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(/static/media/Roboto-Bold.e07df86c.ttf);
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url(/static/media/Roboto-Regular.11eabca2.ttf);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0px;
}

.dk-main {
  margin: 0 auto;
}
.dk-head {
  font-size: 28px;
  letter-spacing: 0px;
  color: #5a5a5a;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: 200;
}
.dk-para {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-top: -13px;
}

.dk-deviceCard {
  margin-top: 30px;
}
.input-opt-box {
  height: 200px;
}
.input-opt-box .errorMsg {
  padding: 0;
  margin: 0;
  padding-top: 10px;
}
.input-opt-box input {
  width: 160px !important;
  height: 180px !important;
}
.grid-container {
  padding: 0px !important;
}
.cancel {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: #4d4d4d;
  font-size: 14px;
  letter-spacing: 1.26px;
  -webkit-align-self: center;
          align-self: center;
  margin-right: 20px;
  cursor: pointer;
}
.opt-buttons {
  display: -webkit-flex;
  display: flex;
}

.verificationBox {
  margin: 0px auto;
  width: 430px;
  border-bottom: 1px solid #eeeff0;
}
.verificationBox > div {
  margin: 0px !important;
}
.deviceNameTextBox {
  margin: 0px auto;
  width: 570px;
  position: relative;
}
.deviceNameTextBox p {
  margin-top: 31px !important;
}
.autoFocusedText {
  position: absolute;
    left: 25.1%;
    top: 53%;
    font-size: 34px;
    font-family: 'Roboto-Regular' !important;
    color: #d3d8da;
    letter-spacing: 0.32px;
    opacity: 1;
}

.deviceNameTextBox p {
  text-align: center;
  font-size: 22px;
  color: #5A5A5A;
}
.deviceNameTextBox label {
  visibility: hidden;
}
.deviceNameTextBox input::-webkit-input-placeholder {
  caret-color: transparent;
  text-align: center;
  font-size: 34px;
  font-family: 'Roboto-Regular' !important;
  color: #4D4D4D !important;
  letter-spacing: 0.32px;
  opacity: 1;
}
.deviceNameTextBox input:-ms-input-placeholder {
  caret-color: transparent;
  text-align: center;
  font-size: 34px;
  font-family: 'Roboto-Regular' !important;
  color: #4D4D4D !important;
  letter-spacing: 0.32px;
  opacity: 1;
}
.deviceNameTextBox input,
.deviceNameTextBox input::placeholder {
  caret-color: transparent;
  text-align: center;
  font-size: 34px;
  font-family: 'Roboto-Regular' !important;
  color: #4D4D4D !important;
  letter-spacing: 0.32px;
  opacity: 1;
}

.verificationBtn {
  width: 430px;
  margin: 70px auto 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.verificationBox .verifyParaSuccess {
  font-family: 'Roboto-Light' !important;
}
.deviceTextbox {
  height: 54px;
}
.char {
  width: 430px;
  margin: 0px auto;
  text-align: center;
}
.char p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #d3d8da;
  margin-top: 5px;
}

.updradePara {
  text-align: center;
}
.updradePara p {
  color: #818e94;
  font-family: 'Roboto-Regular';
}

.roboto-light {
  font-family: 'Roboto-Light' !important;
}
.roboto-medium {
  font-family: 'Roboto-Medium' !important;
}
.roboto-regular {
  font-family: 'Roboto-Regular' !important;
}
.accordion {
  width: 570px !important;
  margin: 0px !important;
}
.orgForm3 {
  margin: 0px !important;
  width: 100% !important;
}
.headTitle {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.schedule .MuiPaper-elevation1 {
  box-shadow: none !important;
  margin: 0px !important;
  border: 1px solid #e8e9e9;
  border-top: none;
}
.tab_header_button > div > div {
  width: 135px !important;
  height: 36px !important;
}
.tab_header_button > button {
  height: 36px !important;
}
.tab_header_button button:disabled {
  /* color: red !important; */
}

aside ul div:hover div {
  opacity: 1;
}

.add_another_btn {
  display: -webkit-flex;
  display: flex;

  margin: 0px;
  padding: 0px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.add_another_btn img {
  cursor: pointer;
}
.add_another_btn span {
  color: #2a82f6;
  font-size: 14px;
  font-family: 'Roboto-Medium' !important;
  text-transform: uppercase;
  letter-spacing: 1.26px;
  margin-left: 8px;
  cursor: pointer;
}

.side-drawer__content {
  padding: 2px !important;
}
.side-drawer__title {
  margin-bottom: 22px !important;
}
.PageHeaderWithTabs .MuiPaper-rounded {
  border-radius: 0px;
}
.detailsTable .MuiPaper-outlined{
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.detailsTable .lower {
  border: none;
  border-top: 1px solid #e8e9e9 !important;
}
.playlists-main-div{
  margin-left: 70px;
  margin-right: 80px;
  margin-top: 40px;
}
.app-bar>section>aside{
  background-color:white !important;
}
.nav_container2{
  background: transparent linear-gradient(180deg,#7266fb,#7266fb) 0 0 no-repeat padding-box;
}
.DKfooter>div{
margin-bottom: 10px;
}
.week-days>div>div{
  border: none;
}
.week-days>div>div>div{
  padding-left: 3px !important;
}

.verificationBox {
  width: 570px !important;
}

 .middle-section .inner-section .MuiGrid-root .org-accordian-single-tab:last-child{
  border-radius: 4px !important;
}
.orgForm {
  width: 40%;
  margin: 0 auto;
}

@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Regular.11eabca2.ttf);
}

@font-face {
  font-family: 'Light';
  src: url(/static/media/Roboto-Light.88823c20.ttf);
}

.orgForm2 {
  width: 40%;
  margin: 0 auto;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 0px 0px 0px, 0px 0px 2px 1px rgba(0, 0, 0, 0.14),
    0px 1px 0px -1px rgba(0, 0, 0, 0.12) !important;
}

/* .MuiMenu-paper {
  top: -2px !important;
} */

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiFormLabel-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54);
}
.select-img-green-check{
  background: gray;
  position: absolute;
  z-index: 9;
  opacity: 0.5;
  width: 40px;
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.MuiInputBase-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  /* color: black !important; */
}

.MuiMenuItem-root,
.MuiMenuItem-root-864 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiFormLabel-root-112 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiInputBase-input-145 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiFormLabel-root-174 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiInputBase-input-207 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiInputBase-root-289 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiInputBase-input-300 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiFormLabel-root-46,
.MuiInputBase-input-79,
.MuiFormLabel-root-139,
.MuiInputBase-input-172 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiSelect-select:focus {
  background: none !important;
}

.MuiFormLabel-root-658,
.MuiInputBase-root-690,
.MuiFormLabel-root-737 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiMenuItem-root-864 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiSelect-select-667:focus {
  background: none;
}

.prop {
  margin-top: 2px;
  width: 55%;
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.txt {
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.name2 {
  font-size: 15px;
  width: 100%;
  /* margin-top: 5px; */
}

.bold {
  font-weight: bold;
}

.red-border {
  width: 69px;
  height: 27px;
  background-color: #f9ebed;
}

.required {
  color: #c44058;
  font-weight: bold;
}

.lower {
  border: 1px solid #e8e9e9;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 20px;
}

.edit {
  margin-right: 30px;
  color: #2490f9;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.25px;
  cursor: pointer;
}

.onboarding {
  width: 123px;
  height: 32px;
  background: #fef9eb 0% 0% no-repeat padding-box;
  border-radius: 16px;
  -webkit-align-self: center;
          align-self: center;
  margin-left: 10px;
}

.onBoardingText {
  letter-spacing: 0.25px;
  color: #eeb000;
  text-transform: uppercase;
  font-family: 'buttons' !important;
  margin-top: 7px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-align: center;
}

.approved {
  width: 123px;
  height: 32px;
  background: #effbf5 0% 0% no-repeat padding-box;
  border-radius: 16px;
  -webkit-align-self: center;
          align-self: center;
  margin-left: 10px;
}

.approvedText {
  letter-spacing: 0.25px;
  color: #3cc480;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-top: 7px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-align: center;
}

.blocked {
  width: 123px;
  height: 32px;
  background: #f9ebed 0% 0% no-repeat padding-box;
  border-radius: 16px;
  -webkit-align-self: center;
          align-self: center;
  margin-left: 10px;
}

.blockedText {
  letter-spacing: 0.25px;
  color: #c44058;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-top: 7px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-align: center;
}

.textAline {
  text-align: center !important;
}

.orgForm {
  width: 37%;
  margin: 0 auto;
}

.orgForm3 {
  width: 50%;
  margin: 0 auto;
}

.orgForm4 {
  width: 570px;
  margin: 0px auto;
}

.head {
  background: #f7f8fa 0% 0% no-repeat padding-box;
  border: 1px solid #d3d8da;
  border-radius: 4px 4px 0px 0px;
  margin-top: 20px;
}

.headTitle {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  letter-spacing: 0.13px;
  color: #818e94;
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 20px;
}

.accordion {
  width: 536px;
  margin-left: 8px;
}

.msgTitle {
  margin-left: 30px !important;
}

.msgDesc {
  margin-left: -30px !important;
}

.accTitle {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: #4d4d4d !important;
  font-weight: 600 !important;
}

.accDate {
  color: #818e94 !important;
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.about,
.contact {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: #4d4d4d;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.26px;
}

.about-us,
.contact-us {
  margin-left: 30px;
  border-bottom: 1px dashed #e8e9e9;
  width: 940px;
}

.desc {
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 12px;
  margin-bottom: 3px;
}

.contact-desc {
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: -10px;
}

.lowerEditor {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 940px;
}

.lowerEditorService {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 570px;
}

.lowerEditorPara {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgb(182, 185, 185);
  font-size: 12px;
}

.descPara {
  letter-spacing: 0.4px;
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 12px;
  margin-top: -15px;
}

.orgSave {
  letter-spacing: 1.25px;
  color: #2490f9;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
}

.add {
  width: 320px;
  height: 92.5%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #0000001a;
  opacity: 1;
  z-index: 999;
  position: fixed;
  top: 75px;
  right: 20px;
}

.add2 {
  width: 320px;
  height: 92.5%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #0000001a;
  opacity: 1;
  z-index: 999;
  position: fixed;
  top: 75px;
  right: 20px;
}

.lowerDiv {
  border: none !important;
  height: 50vh !important;
  position: relative;
}

.lowerDiv-img {
  border: none !important;
  height: 40vh !important;
  position: relative;
}

.lowerDiv-img .lower-btns {
  border-top: 1px dashed #e8e9e9 !important;
  padding-top: 40px;
  position: absolute;
  bottom: 0px !important;
}

.lowerDiv .lower-btns {
  border-top: 1px dashed #e8e9e9 !important;
  padding-top: 40px;
  position: absolute;
  bottom: 0px !important;
}

.overlay {
  width: 90%;
  height: 90.5%;
  background-color: black;
  position: absolute;
  z-index: 999;
  opacity: 0.1;
  left: 0px;
  top: 75px;
}

.overlay2 {
  width: 90%;
  height: 90.5%;
  background-color: black;
  position: absolute;
  z-index: 999;
  opacity: 0.1;
  left: 0px;
  top: 75px;
}

.add2 div div div div {
  border: none !important;
}

.add2 div .repeats div div {
  border: none !important;
}

.addRole {
  letter-spacing: 1.26px;
  color: #2490f9;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 7px;
  margin-top: 3px;
  cursor: pointer;
}

.memberDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 570px;
}

.memberPara {
  letter-spacing: 0px;
  color: #818e94;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e9e9;
  width: 570px;
}

.speakerDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-top: 1px solid #e8e9e9;
  padding-top: 20px;
  margin-top: 32px;
}

.speakerPara {
  letter-spacing: 0px;
  color: #818e94;
  font-size: 14px;
  border-bottom: 1px solid #e8e9e9;
  /* width: 570px; */
  padding-bottom: 20px;
}

.MuiAlert-standardError,
.MuiAlert-standardSuccess {
  width: 74% !important;
  margin: 0 !important;
  margin-top: -22px !important;
}

#demo-simple-select:focus {
  background: none !important;
}

.anchor {
  margin-top: 15px;
  margin-left: 5px;
  text-decoration: none;
  width: 18px;
  height: 18px;
  text-align: center;
  padding: 0;
  border-radius: 100px;
  display: inline-block;
  background-color: #3cc480;
  border-color: #3cc480;

  vertical-align: text-top;
  color: #fff;
}


.edit-org-primary {
  margin-right: 15px;
}

.cardDrawer {
  padding: 0px !important;
  width: 100%;
}

.cardDrawer .MuiPaper-outlined{
  border: none;
}

.dashedClass {
  border-bottom: 1px dashed #818e94;
  margin: 8px !important;
  padding-bottom: 30px;
}

.dashedClass2 {
  border-bottom: 1px solid #818e94;
  margin: 8px !important;
  padding-bottom: 30px;
}

.dashedClass .MuiInputBase-input {
  cursor: pointer;
}

.orgForm4 .MuiFormControl-root {
  margin: 0px !important;
}

.dashedClass input {
  cursor: pointer;
}

.dashedClass2 input {
  cursor: pointer;
}

.MuiInputAdornment-positionEnd {
  cursor: pointer;
}

.speakersCont div::before {
  border-bottom: 1px solid #d3d8da !important;
}

.WAMuiChipInput-underline-14.WAMuiChipInput-error-15:after {
  border-width: 1px !important;
  border-bottom-color: #f44336;
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336 !important;
}

.orgName h2 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 16px;
  color: #4d4d4d;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 10px;
  margin-top: 6px;
}

.orgName p {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 16px;
  color: #818e94;
  letter-spacing: 0.15px;
  margin: 0px;
  width: 250px;
  box-sizing: border-box;
  line-height: 25px;
}

.activepara p {
  font-family: 'Roboto';
  font-size: 16px;
  color: #818e94;
  letter-spacing: 0.15px;
}

.waktcontainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.waktcontainer > div {
  width: 46%;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
  border-color: #D3D8DA!important;
}

.waktcontainer input {
  pointer-events: none;
  cursor: default;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
  cursor: default;
}

.add2 {
  overflow-y: scroll !important;
  height: 700px;
  padding-right: 20px;
  scrollbar-width: thin;
  scrollbar-color: #ebebeb #fff;
}

.add2::-webkit-scrollbar {
  width: 3px;
  height: 18px;
}

.add2::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px #ebebeb !important;
  background-color: #ebebeb !important;
  border-radius: 0px;
  height: 50px !important;
  max-height: 20px;
  background-clip: content-box;
}

.add2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #fff;
  background-color: #fff;
  border-radius: 0px;
  margin: 0 30px !important;
}

.add2 {
  width: 320px;
  height: 92.5%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 3px #0000001a;
  opacity: 1;
  z-index: 999;
  position: fixed;
  top: 75px;
  right: 18px;
}

.repeats > p:first-child {
  font-size: 22px;
  font-family: 'Roboto';
  letter-spacing: 0px;
  color: #5a5a5a;
  opacity: 1;
  margin-top: 50px;
  margin-bottom: 26px;
}

.input_suffix_div {
  position: relative;
  padding: 0px !important;
}

.input_suffix_div input {
  caret-color: transparent;
}

.input_suffix {
  position: absolute;
  left: 13px;
  top: 22px !important;
}

.input_suffix_2 {
  position: absolute;
  left: 22px;
  top: 22px;
}
.DrawerRepeatDays {
 padding-left: 3px !important;
}
.input_suffix3 {
  position: absolute;
  left: 31px;
  top: 32px;
}

.salahStatus h2 {
  font-size: 16px;
  font-family: 'Roboto';
  letter-spacing: 0.15px;
  color: #4d4d4d !important;
  opacity: 1;
}

ul {
  min-width: 200px;
}

.DrawerRepeatDays div:first-of-type {
  letter-spacing: 0.15px;
  color: #818e94 !important;
  opacity: 1;
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-bottom: 0px !important;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}
.daily {
  margin : 0px !important
}

.daily p {
  letter-spacing: 0.15px;
  color: #818e94;
  opacity: 1;
  font-size: 16px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding-bottom: 0px !important;
  margin-top: 20px;
  margin-bottom: 10px;
}

.salahStatus p {
  font-size: 12px;
  font-family: 'Roboto';
  letter-spacing: 0px;
  color: #818e94;
  opacity: 1;
}

.time_zone_field {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.time_zone_field p {
  letter-spacing: 0.15px;
  color: #4d4d4d;
  opacity: 1;
  font-size: 16px;
  font-family: 'Roboto';
  margin-left: 14px;
  margin: 16px 8px;
}

.time_zone_field span {
  letter-spacing: 0.4px;
  color: #2a82f6;
  font-size: 12px;
  font-family: 'Roboto';
  cursor: pointer;
}

.wakt_feild {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin-top: 15px;
}

.wakt_feild div:last-of-type p:first-of-type {
  margin: 0;
  letter-spacing: 0.15px;
  color: #4d4d4d;
  opacity: 1;
  font-size: 16px;
  font-family: 'Roboto';
  margin-left: 14px !important;
}

.wakt_feild div:last-of-type p:last-of-type {
  letter-spacing: 0.4px;
  opacity: 1;
  font-size: 12px;
  font-family: 'Roboto';
  margin-left: 14px !important;
  margin-top: 6px !important;
  color: #818e94;
}

.wakt_feild .change {
  letter-spacing: 0.4px;
  color: #2a82f6;
  font-size: 12px;
  font-family: 'Roboto';
  cursor: pointer;
}

.salah-para {
  font-family: 'Roboto' !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #2a82f6 !important;
}

.MuiFormLabel-root.Mui-error.MuiFormLabel-root.Mui-focused {
  color: red !important;
}

.add3 {
  overflow-y: scroll !important;
  width: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 5px #0000001a;
  opacity: 1;
  z-index: 9999;
  position: fixed;
  top: 75px;
  right: -5px;
  padding: 30px;
  max-height: calc(100vh - 9rem);
  height: 100%;
}

.add3 h3 {
  letter-spacing: 0px;
  color: #5a5a5a;
  opacity: 1;
  font-family: 'Light';
  margin: 0px 0px 22px 0px;
  font-size: 22px;
  padding: 6px 8px;
  scrollbar-width: thin;
  scrollbar-color: #ebebeb #fff;
}

.add3::-webkit-scrollbar {
  width: 3px;
  height: 18px;
  scrollbar-width: thin;
}

.add3::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px #ebebeb !important;
  background-color: #ebebeb !important;
  border-radius: 0px;
  height: 50px !important;
  max-height: 20px;
  background-clip: content-box;
}

.add3::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #fff;
  background-color: #fff;
  border-radius: 0px;
  margin: 0 30px !important;
}

/* .add3 div div div div {
  border: none !important;
} */
.add3 div .repeats div div {
  border: none !important;
}

.add3 div div div div fieldset {
  width: 280px;
}

.add3 div div div div fieldset div:last-of-type hr {
  clear: both;
  visibility: hidden;
}

.add3 .repeats {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.base-text-field label.Mui-error {
  color: rgba(0, 0, 0, 0.54) !important;
}

label.MuiFormLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.54) !important;
}

.DrawerCard_conainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.DrawerCard_conainer .MuiPaper-outlined:hover {
  background-color: hsla(0, 0%, 91.4%, 0.04) !important;
}

.DrawerCard section svg {
  position: inherit !important;
}

.DrawerCard_conainer .DrawerCard section svg {
  position: inherit !important;
}

.requirementCont {
  position: relative;
}

.requirementCont h1 {
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #818e94;
  opacity: 1;
  font-family: 'Light';
  margin: 0px;
  padding: 14px 8px 0px;
}

.dollarIcon {
  position: absolute;
    bottom: 32px;
    color: #818e94;
    font-weight: bold;
    font-size: 18px;
    font-weight: lighter;
    top: 54px;
    font-size: 16px;
}

.requirementCont input,
.requirementCont label {
  padding-left: 14px;
}


.cardDrawer li > div:first-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.sidebar_parent .attendees h1 {
  font-size: 16px;
  letter-spacing: 0.15px;
  color: #818e94;
  opacity: 1;
  font-family: 'Light';
  margin: 0px;
  padding: 14px 8px 0px;
  margin-bottom: 10px;
}

.sidebar_parent .attendees .gender {
  margin-bottom: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sidebar_parent .attendees .gender:nth-child(3) div.MuiCardContent-root {
  padding-right: 9px !important;
}

.sidebar_parent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: inherit;
}

.sidebar_parent h1 {
  font-family: 'Light';
  letter-spacing: 0.15px;
  color: #818e94;
  opacity: 1;
  font-size: 16px;
  padding: 6px 0px;
  margin: 0px;
}

.potluck div {
}

.potluck div div {
  height: 40px !important;
}

.potluck p {
  letter-spacing: 0.4px;
  color: #818e94;
  opacity: 1;
  font-family: 'Roboto';
  font-size: 14px;
}

.requirementCont div div div {
  border: none !important;
}

.requirementCont div div div li div {
  padding-left: 0px !important;
}

.chipInput {
  caret-color: transparent;
}

.chipInput > p {
  position: absolute;
  bottom: -20px;
}

.chipInput .Mui-error .MuiInputAdornment-positionEnd {
  color: red;
}

.chipInput .WAMuiChipInput-underline-19:after {
  border-bottom-width: 1px !important;
}

.potluck {
  margin: 24px 0px 28px 0px;
}

.foodlistitem {
  font-family: 'Roboto';
  font-size: 12px;
  color: #c44058;
  padding: 2px 8px;
}

.speakersCont {
  margin-top: 30px;
}

.speakers > div li {
  padding: 0px !important;
}

.speakers div > div:first-child {
  border: none !important;
}

.speakers div li:last-child div {
  border-bottom: 0px !important;
}

.speakers .showMore {
  letter-spacing: 1.25px;
  color: #2a82f6;
  text-transform: uppercase;
  opacity: 1;
  font-size: 14px;
  font-family: 'Roboto';
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}

.speakers .first_child {
  border-bottom: 1px dashed #d3d8da;
  padding-bottom: 30px;
}

.second_child {
  padding: 30px 0px 0px 0px;
}

.second_child .email_div label:first-child {
  margin-bottom: 25px;
  padding: 0px 9px;
}

.second_child .email_div svg {
  width: 24px;
  height: 24px;
}

.second_child .email_div label p {
  font-size: 14px !important;
  color: #818E94 !important;
  margin-left: 10px;
}

.second_child .email_div label:not(:first-child) p {
  color: #bcc0c2 !important;
  font-family: 'light' !important;
}

.second_child h1 {
  letter-spacing: 0px;
  color: #818e94;
  opacity: 1;
  font-size: 12px;
  font-family: 'light';
}

.second_child div:first-child p:first-of-type {
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  font-family: 'buttons';
  line-height: 22px;
  color: #4d4d4d;
}


.side-drawer__content {
  overflow-y: auto !important;
  height: 80% !important;
  width: 100% !important;
  box-sizing: content-box;
  scrollbar-width: thin;
  scrollbar-color: #00000036 #ffffffbf !important;
}


.chip-icon {
  position: absolute !important;
  right: 24px;
  bottom: 2px;
  width: 0px;
}

.speaker .chipInput label {
  color: rgba(0, 0, 0, 0.42) !important;
  margin-top: 8px;
  font-size: 14px;
}

.speaker .chipInput label.MuiInputLabel-shrink {
  margin-top: 0px;
}

.speakers {
  position: relative;
  height: 100%;
}

.side-drawer__content .email_div {
  position: absolute;
  bottom: 0px;
}

.date_time_container {
  /* width: 310px; */
}

.MuiInputAdornment-root {
  color: #818e94;
}

.speakersCont svg {
  color: #818e94 !important;
}

.chip_list svg {
  color: #818e94 !important;
}

h2.title {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: #5a5a5a;
  font-size: 22px;
  font-weight: 200;
}

.speakersCont div::after {
  border-bottom-color: #f44336;
  border-width: 1px !important;
}

.dateAndTime .base-text-field {
  margin: 0px;
  margin-bottom: 14px;
}
.ul-class ul {
  min-width: 100px;
}

.ul-class .ellipse li img {
  z-index: 1;
}

.event_Image .MuiPaper-outlined {
  border: none !important;
}
.second_child .email_div label:nth-child(2) span:first-child {
  color: #bcc0c2 !important;
}
.second_child .email_div label:nth-child(2) {
  pointer-events: none;
  padding: 0px 9px;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #3cc480 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #3cc480 !important;
}

.salah-bottom, .salah-head {
  border: 1px solid #e8e9e9;
  background: #fff;
}

.salahsettingContainer{
  margin-top: 30px;
  cursor: pointer;
}

.salah-title{
  font-weight: 300 !important;
}

.drawer_form .base-text-field{
  margin-bottom: 26px;
  min-height: 48px !important;
}

.full_width_button button{
  width: 100%;
}

.full_width_button button .MuiCardContent-root {
  padding: 16px;
  width: 93%;
}

.full_width_button button .MuiPaper-root{
  width: 100% !important;
}

.drawer_btn{
  display: -webkit-flex;
  display: flex;
}

.drawer_btn .MuiPaper-root{
  width: 100% !important;
}

.drawer_btn .MuiButton-text {
  padding: 6px 8px;
  width: 100%;
}

.drawer_btn div{
  -webkit-flex:1 1;
          flex:1 1;
}
.attendees .MuiCardContent-root {
  height: 38px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.add-attendee-input {
  padding: 6px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.add-attendee-input input {
  border: 1px solid #D3D8DA;
  border-radius: 4px;
  opacity: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  width: 224px;
  height: 32px;
  padding: 16px;
  font-size: 16px;
  font-size: 16px;
  color: rgb(77, 77, 77);;
}
.add-attendee-input input ::-webkit-input-placeholder{
  color: #BCC0C2;
}
.add-attendee-input input :-ms-input-placeholder{
  color: #BCC0C2;
}
.add-attendee-input input ::placeholder{
  color: #BCC0C2;
}
.add-attendee-input img {
  cursor: pointer;
}

.side-drawer__container .MuiSwitch-colorSecondary.Mui-checked {
  color: #2490F9 !important;
}

.side-drawer__container .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #2490F9 !important;
  opacity: .4;
}


.DrawerCard_conainer button .MuiCardContent-root{
  border: none !important;;
}

.sidebar_parent button .MuiCardContent-root{
  border: none !important;;
}

.SalahSettingSwitchContainer{
  margin-left: 8px;
}
.SalahSettingSwitchContainer .FormSwitch{
  -webkit-align-items: start !important;
          align-items: start !important;
  width: 104%;
}

.ellipse li img {
  object-fit: cover;
}

.DrawerSpBorder{
  border-bottom:#2490F9 1px dashed !important;
  height: 20px;

}

.isEndDate .MuiFormControlLabel-root{
  padding-bottom: 15px;
}


.isEndDate .MuiTextField-root{
  padding-bottom: 17px;
}

.isStartDate .MuiFormControlLabel-root{
  padding-bottom: 15px;
}


.isStartDate .MuiTextField-root{
  padding-bottom: 17px;
}

.DrawerSocialDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 20px;
  border-top: #d7d7d7  1px dashed;
  padding-top: 15px;
}

.DrSwitchBox{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  color:#4D4D4D;
}

.DrSwitchBox img{
  margin-right: 10px;
}

.drw_field{
  padding-top: 15px;
}

.drw_field label{
  font-size: 14px;
  color:#4D4D4D;
  font-weight: 400;
}

.drw_field .drw_fieldArea {
  border: none;
  border-bottom: #e7e7e7 1px solid;
  width: 100%;
  margin-top: 10px;

}

.drw_field .drw_fieldArea:focus {
  border: none;
  outline: none;
  border-bottom: #e7e7e7 1px solid;
}


.TimeFiled .MuiInput-underline:before{
display: none;
}

.TimeFiled .MuiInput-underline:after {
  display: none;
}

.TimeFiled .MuiInputBase-formControl {
  border: #E4E8EC 1px solid;
  height: 56px;
  border-radius: 4px;
  padding: 0 15px;
  margin-top: 26px;
}

.container{
  width: 55%;
  margin: 0 auto;
}
.container .MuiPaper-outlined,.container-success .MuiPaper-outlined{
  border:none !important
}
.container div div div{
  margin-right: auto;
  margin-left: auto;
}
#main-footer-border{
  border-top: 1px solid rgba(129, 142, 148, 0.3) !important;
}
#root > div > div {
  border: none
}

.signin > div > div > div {
  border: none !important;
}
.orgForm {
  width: 40%;
  margin: 0 auto;
}

.orgForm2 {
  width: 40%;
  margin: 0 auto;
  height: 60%;
}
.ondisableText{
  font-weight: 100;
  color: #818e94;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 0px 0px 0px, 0px 0px 2px 1px rgba(0, 0, 0, 0.14),
    0px 1px 0px -1px rgba(0, 0, 0, 0.12) !important;
}

/* .MuiMenu-paper {
  top: -2px !important;
} */

.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiFormLabel-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
.MuiInputBase-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: black !important;
}
.MuiMenuItem-root,
.MuiMenuItem-root-864 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiFormLabel-root-112 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
.MuiInputBase-input-145 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiFormLabel-root-174 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
.MuiInputBase-input-207 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiInputBase-root-289 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiInputBase-input-300 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiFormLabel-root-46,
.MuiInputBase-input-79,
.MuiFormLabel-root-139,
.MuiInputBase-input-172 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
.MuiSelect-select:focus {
  background: none !important;
}

.MuiFormLabel-root-658,
.MuiInputBase-root-690,
.MuiFormLabel-root-737 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiMenuItem-root-864 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiSelect-select-667:focus {
  background: none;
}



.prop {
  margin-top: 2px;
  width: 55%;
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.txt {
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}
.name2 {
  font-size: 14px;
}
.name2-org {
  font-size: 15px;
  width: 100%;
  margin-top: 5px;
}
.bold {
  font-weight: bold;
}

.red-border {
  width: 69px;
  height: 27px;
  background-color: #f9ebed;
}
.red-border-2 {
  width: 69px;
  height: 27px;
  background-color: #f9ebed;
}
.required {
  color: #c44058;
  font-weight: bold;
}

.lower {
  border: 1px solid #e8e9e9;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 20px;
}

.edit {
  margin-right: 30px;
  color: #2490f9;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.25px;
  cursor: pointer;
}

.onboarding {
  width: 123px;
  height: 32px;
  background: #fef9eb 0% 0% no-repeat padding-box;
  border-radius: 16px;
  -webkit-align-self: center;
          align-self: center;
  margin-left: 10px;
}

@font-face {
  font-family: 'buttons';
  src: url(/static/media/Roboto-Light.88823c20.ttf);
}

.onboardingText {
  letter-spacing: 0.25px;
  color: #eeb000;
  text-transform: uppercase;
  font-family: 'buttons' !important;
  margin-top: 7px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-align: center;
}

.approved {
  width: 123px;
  height: 32px;
  background: #effbf5 0% 0% no-repeat padding-box;
  border-radius: 16px;
  -webkit-align-self: center;
          align-self: center;
  margin-left: 10px;
}
.approvedText {
  letter-spacing: 0.25px;
  color: #3cc480;
  text-transform: uppercase;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
  margin-top: 7px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-align: center;
}
.pending {
  width: 123px;
  height: 32px;
  background: #effbf5 0% 0% no-repeat padding-box;
  border-radius: 16px;
  -webkit-align-self: center;
          align-self: center;
  margin-left: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.pendingText {
  letter-spacing: 0.25px;
  color: #3cc480;
  text-transform: uppercase;
  margin-top: 6px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-align: center;
  font-size: 14px !important;
  margin: 0 !important;
}
.blocked {
  width: 123px;
  height: 32px;
  background: #f9ebed 0% 0% no-repeat padding-box;
  border-radius: 16px;
  -webkit-align-self: center;
          align-self: center;
  margin-left: 10px;
}
.blockedText {
  letter-spacing: 0.25px;
  color: #c44058;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  margin-top: 7px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
  text-align: center;
}

.orgForm {
  width: 37%;
  margin: 0 auto;
}

.orgForm3 {
  width: 50%;
  margin: 0 auto;
}

.orgForm4 {
  width: 570px;
  margin: 0px auto;
}

.head {
  background: #f7f8fa 0% 0% no-repeat padding-box;
  border: 1px solid #d3d8da;
  border-radius: 4px 4px 0px 0px;
  margin-top: 20px;
}

.headTitle {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  letter-spacing: 0.13px;
  color: #818e94;
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 20px;
}
.accordion2 {
  width: 100%;
  margin-left: 8px;
}

.msgTitle {
  margin-left: 30px !important;
}

.msgDesc {
  margin-left: -30px !important;
}

.accTitle {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: #4d4d4d !important;
  font-weight: 600 !important;
}

.accDate {
  color: #818e94 !important;
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.about,
.contact {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: #4d4d4d;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.26px;
}

.about-us,
.contact-us {
  margin-left: 30px;
  border-bottom: 1px dashed #e8e9e9;
  width: 940px;
}

.desc {
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 12px;
  margin-bottom: 3px;
}

.contact-desc {
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 12px;
  margin-bottom: 3px;
  margin-top: -10px;
}

.lowerEditor {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 940px;
}

.lowerEditorService {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 570px;
}

.lowerEditorPara {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  color: rgb(182, 185, 185);
  font-size: 12px;
}

.descPara {
  letter-spacing: 0.4px;
  color: #818e94;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 12px;
  margin-top: -15px;
}

.orgSave {
  letter-spacing: 1.25px;
  color: #2490f9;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
  font-weight:500;
  padding: 7px 0px;
}

.add {
  width: 320px;
  height: 91.5%;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000001a;
  opacity: 1;
  z-index: 999;
  position: fixed;
  top: 74px;
  right: -3px;
}

.add,
.add2 {
  overflow-y: auto !important;
  scrollbar-width: thin;
  scrollbar-color: #ebebeb #fff;
  /* height: 700px;
  padding-right: 20px; */
}
div .add::-webkit-scrollbar,
div .add2::-webkit-scrollbar {
  width: 3px;
  height: 18px;
}
div .add::-webkit-scrollbar-thumb,
div .add2::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px #7266fb !important;
  background-color: #7266fb !important;
  border-radius: 0px;
  height: 50px !important;
  max-height: 20px;
  background-clip: content-box;
}
div .add::-webkit-scrollbar-track,
div .add2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #594fce;
  background-color: #594fce;
  border-radius: 0px;
  margin: 0 30px !important;
}

.add2 {
  width: 320px;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #0000001a;
  opacity: 1;
  z-index: 999;
  position: fixed;
  top: 75px;
  right: -3px;
  overflow: hidden;
}

.lowerDiv {
  border-top: 1px dashed #e8e9e9;
  height: 100px;
  margin-top: 100px;
  margin-bottom: 20px;
}

.overlay {
  width: 90%;
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: 999;
  opacity: 0.1;
  left: 0px;
  top: 75px;
}
.overlay2 {
  width: 90%;
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: 999;
  opacity: 0.1;
  left: 0px;
  top: 75px;
  overflow: hidden;
}

.add2 div div div div {
  border: none !important;
}

.addRole {
  letter-spacing: 1.26px;
  color: #2490f9;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 7px;
  margin-top: 3px;
  cursor: pointer;
}

.addRoleDiv {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #e8e9e9;
  padding-bottom: 20px;
}

.memberDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 570px;
}

.memberPara {
  letter-spacing: 0px;
  color: #818e94;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e9e9;
  width: 570px;
}

.speakerDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* width: 570px; */
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #e8e9e9;
}

.speakerPara {
  letter-spacing: 0px;
  color: #818e94;
  font-size: 14px;
  border-bottom: 1px solid #e8e9e9;
  /* width: 570px; */
  width: 100%;
  padding-bottom: 20px;
}

.MuiAlert-standardError,
.MuiAlert-standardSuccess {
  width: 74% !important;
  margin: 0 !important;
  margin-top: -22px !important;
}

#demo-simple-select:focus {
  background: none !important;
}

.anchor {
  margin-top: 15px;
  margin-left: 5px;
  text-decoration: none;
  width: 18px;
  height: 18px;
  text-align: center;
  padding: 0;
  border-radius: 100px;
  display: inline-block;
  background-color: #3cc480;
  border-color: #3cc480;

  vertical-align: text-top;
  color: #fff;
}
/* .icon {
  display: inline-block;
  font-size: 1rem !important;
  padding: 5px !important;

  margin: -2px;
} */

.anchor2 {
  margin-top: 15px;
  margin-left: 5px;
  text-decoration: none;
  width: 18px;
  height: 18px;
  text-align: center;
  padding: 0;
  border-radius: 100px;
  display: inline-block;
  background-color: #3cc480;
  border-color: #3cc480;

  vertical-align: text-top;
  color: #fff;
}
.icon2 {
  display: inline-block;
  font-size: 1rem !important;
  margin: -1px;
  margin-left: 0px;
  padding-right: 1px;
}
.onboarding-form,
.onboarding-form-error {
  width: 260px;
}



.char-count {
  font-size: 12px;
  color: #b6b9b9;
}

.service-helper-text {
  color: #818e94;
  font-size: 12px;
  margin-top: 10px;
}

.MuiFormHelperText-root {
  font-family: Arial, Helvetica, sans-serif !important;
  color: #f44336 !important;
  width: 100%;
}

.MuiFormHelperText-root.Mui-error {
  color: #f44336 !important;
}
.MuiFormHelperText-root.Mui-error span {
  color: #f44336 !important;
}

.Mui-error + span {
  color: #f44336 !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.stepLabel {
  font-family: Arial, Helvetica, sans-serif !important;
}


.orgForm3:nth-child(5) {
  cursor: pointer;
}

.stripe-success-card {
  padding-top: 20px;
}
.stripe-failed-card {
  padding-top: 220px;
  padding-bottom: 420px;
}

.stripe-success-card div {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px !important;
}

.stripe-success-card h3,
.stripe-success-card h3 {
  font-family: Arial, Helvetica, sans-serif !important;
}

.verifyParaSuccess,
.baseBtn p {
  font-family: Arial, Helvetica, sans-serif !important;
}

.about-org-scroll {
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: thin;
}

div .about-org-scroll::-webkit-scrollbar {
  width: 3px;
  height: 18px;
}
div .about-org-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px #fff !important;
  background-color: #fff !important;
  border-radius: 0px;
  height: 50px !important;
  max-height: 20px;
  background-clip: content-box;
}
div .about-org-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #ffe;
  background-color: #ffe;
  border-radius: 0px;
  margin: 0 30px !important;
}

.bodyNavWithoutBtn {
  padding-bottom: 250px !important;
}

.tax-exmp {
  margin-left: -20px;
}
.tax-exmp p {
  color: rgba(0, 0, 0, 0.54) !important;
}
.svg-img svg {
  color: #818e94;
}

.error-chip div div:before {
  border-bottom: 1px solid rgb(244, 67, 54) !important;
}

.inquiry-contact {
  padding-left: 5px;
}

.img-uploader div div {
  border: none !important;
}
.img-details li {
  padding-left: 0px !important;
}
.img-details .MuiAvatar-square{
  background-color: rgba(142, 148, 125, 0.5);
}
.setup-footer {
  margin-left: auto;
}
.side-drawer__container {
  overflow: auto;
  width: 320px !important;
  padding: 20px !important;
}

.side-drawer__container .MuiFormControl-root{
  margin: 0 !important;
}

.chip-error-text {
  color: #f44336;
  font-size: 12px;
  margin-top: 8px;
}

.form_info{
  color: #818E94;
  line-height: 20px;
  font-size: 12px;
  margin-left:10px;
  margin-top:-1px;
}

.app_bar{
  position: fixed;
  top: 0;
  z-index: 9;
}

.org-nav-height >div>div>div>div>div{
  min-height: 115vh !important;
}

.bold {
  font-family: 'Open Sans' !important;
  color: #4d4d4d !important;
}

.prop p {
  font-size: 14px!important;
  font-family: Open Sans,sans-serif !important;
}


.MuiInputBase-root select{
	padding-left:0px;
  padding-top: 5px;
}




.cancel-plan-stepper .stepper_container {
  width: 80% !important;
  margin: 0 auto !important;
}

.feedback-step .cancel-reason-checkbox {
  margin-bottom: 0px;
}

.feedback-step .mainHeading {
  color: #5A5A5A;
  font-size: 22px;
  margin-bottom: 31px;
  font-weight: 400;
  margin-top: 31px;
}

.feedback-step .subHeading {
  color: #818E94;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-top: 0 !important;
}

.FDBdescription{
  color: #818E94;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  font-family: Open Sans,sans-serif !important;
}

.FDBdescription span{
  font-weight: 500;
  color: #4D4D4D;
}

.feedback-step .cancel-reason {
  margin-top: 20px;
}

.feedback-step .cancel-reason .MuiInputBase-multiline.MuiOutlinedInput-multiline {
  background-color: #F7F8FA !important;
  border-color: #E8E9E9;
}

.feedback-step .cancel-reason .MuiInputBase-multiline.MuiOutlinedInput-multiline textarea{
  background-color: #F7F8FA !important;
  color: #818E94 !important;
}


.reason-helpertext {
  color: #B6B9B9;
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 3px;
}

.feedback-step .bodyText {
  display: -webkit-flex;
  display: flex;
  margin-top: 32px;
}

.feedback-step .bodyText .bodyHeadingText {
  color: #4D4D4D;
  font-size: 16px;
  margin: 0;
  margin-bottom: 8px;
}

.feedback-step .bodyText svg{
  font-size: 22px;
  margin-right: 14px;

}


.tooltip-box-cart .baseBtn.button_text {
  color: #fff !important;
}

.kiosk-pricing-modal .current-plan {
   padding: 0px !important;
}

.tooltip-upper-container .Cartdropdown { 
  z-index: 1000;
}

.see-feature-top-bar{
  height: auto !important;
}

.PageHeadingTopleft{
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300;
  font-size:28px;
  color: #5A5A5A;
  margin-left: 8px;
}

.backCard .MuiButton-containedPrimary {
  background-color: transparent !important;
}

.ReceiptsContainer{
  max-width: 970px;
  margin: 0 auto;
  position: relative;
}

.ReceiptsForm{
  min-height: 400px;
  background: #fff;
  border:#E8E9E9 1px solid;
  margin-top: 15px;
  padding: 50px 20px;
}

.ReceiptsContainer .backBtn{
  margin-top: 0 !important;
  left: 0 !important;
  position: relative !important;
}

.ReceiptsForm .RcsptHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
  padding: 0px 64px 0 31px;
}

.ReceiptsForm .DcLogoRcspt {
  width: 190px;
  object-fit: contain;
  position: relative;
  top: 15px;
}
.ReceiptsForm .RcsptHeaderInfo h2{
  font-size: 20px;
  color: #4D4D4D;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 6px;
}

.ReceiptsForm .RcsptHeaderInfo p{
  font-size: 16px;
  color: #818E94;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
}

.ReceiptsForm .RcsptHeaderInfo p span{
  color: #4D4D4D;
  font-weight: 500;

}

.ReceiptsForm .RcsptHeaderInfo p label{
  color: #3CC480;
  font-weight: 600 !important;

}

.BillInfromation{
  background: #F7F8FA;
  padding: 30px;
  margin-top:30px;
}

.BillInfromation{}

.BillInfromation .RcsptHeaderInfoDetails h2{
  font-size: 14px;
  color: #818E94;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.BillInfromation .RcsptHeaderInfoDetails h3{
  font-size: 20px;
  color: #4D4D4D;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  margin-bottom: 6px;
  letter-spacing: 0;
}

.BillInfromation .RcsptHeaderInfoDetails p{
  font-size: 16px;
  color: #4D4D4D;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  letter-spacing: 0;
}

.RcptBiilingTable{
  padding: 30px;
} 
.RcptBiilingTable .MuiPaper-elevation1{
  box-shadow: none !important;
}

.RcptBiilingTable .MuiTableRow-head .MuiTableCell-root{
  font-size:14px;
  font-weight: 500;
  color: #818E94;
  padding: 0px 0 !important;
  text-transform: uppercase;
}

.RcptBiilingTable .MuiTableBody-root .MuiTableCell-root {
  font-size: 14px;
  font-weight: 500;
  color: #4D4D4D;
  padding: 16px 0 !important;
}

.TotalDetailsContainer{
  padding: 0 30px;
}

.TotalDetails {
  text-align: right;
  width: 292px;
  float: right;
}

.TotalDetails p{
  font-size: 14px;
  color: #818E94;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  letter-spacing: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.TotalDetails .boldText{
  font-weight: 600;
  color:#4D4D4D;
}

.RscptFormFooter{
  border-top:#E8E9E9 1px solid;
  margin-top: 338px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 30px;
}

.RscptFormFooter p{
  font-size: 16px;
  color: #818E94;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  letter-spacing: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 2.24px;
}

.invoiceAmountText{
  text-align: left;
}

.RcptBiilingTable{
  padding: 30px;
} 
.RcptBiilingTable .MuiPaper-elevation1{
  box-shadow: none !important;
}

.RcptBiilingTable .MuiTableRow-head .MuiTableCell-root{
  font-size:14px;
  font-weight: 500;
  color: #818E94;
  padding: 0px 0 !important;
}

.RcptBiilingTable .MuiTableBody-root .MuiTableCell-root {
  font-size: 14px;
  font-weight: 500;
  color: #4D4D4D;
  padding: 16px 0 !important;
}

.TotalDetailsContainer{
  padding: 0 30px;
}

.TotalDetails{
  text-align: right;
}

.TotalDetails p{
  font-size: 14px;
  color: #818E94;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  letter-spacing: 0;
  margin-bottom: 5px;
}

.TotalDetails .boldText{
  font-weight: 600;
  color:#4D4D4D;
}

.RscptFormFooter{
  border-top:#E8E9E9 1px solid;
  margin-top: 338px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 30px;
}

.RscptFormFooter p{
  font-size: 16px;
  color: #818E94;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  letter-spacing: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 2.24px;
}

.FormButtonContainer{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.FormButtonContainer .downloadBtn{
  border-radius: 100px;
  font-size: 14px;
  color: #4D4D4D;
  text-transform: uppercase;
  letter-spacing: 1.26px;
  font-weight: 600;
  border: #E8E9E9 1px solid;
}

.card_details_table .bold{
  word-break: break-all;
}

.FullTable .accordion{
  width: 100% !important;
}

.BankCardsContainer .backCard{
  float:left;
}

.activeColor .MuiInputBase-input.Mui-disabled{
  color: #4d4d4d;
}

.CalcelConfirmationContainer{
max-width: 770px;
margin: 0 auto;
}
.CalcelConfirmationContainer h2{
  font-size: 28px;
  color: #4D4D4D;
  letter-spacing: 0px;
  font-weight: 300;
  text-align: center;
}

.CalcelConfirmationContainer p{
  font-size: 16px;
  color: #818E94;
  letter-spacing: 0px;
  font-weight: 300;
  text-align: center;
}

.CalcelConfirmationContainer p a{
  color: #2A82F6;
  cursor: pointer;
}

.CalcelConfirmationContainer button{
  display: inherit;
  margin: 0 auto;
  margin-top: 24px !important;
}

.DSHBorderTop{
  border-top: 1px dashed #D3D8DA;
  padding-top: 30px;
  margin-top: 70px;
}

.services_helpertext .lowerEditorService{
  position: absolute;
}

.services_helpertext .MuiFormHelperText-root {
  position: absolute;
  bottom: -23px;
}

.MuiAlert-standardSuccess{
    margin: 0 auto;
    width: 50%;
}

.MuiAlert-standardError{
    margin: 0 auto;
    width: 50%;
}

.verify_form input[type="string"]{
    background: none !important;
}

form main section div div{
    background: none !important;
}

.signin{
    min-height: 100%;
}

.signin div div:nth-child(1){
 margin-left: auto;
 margin-right: auto;
}
.reset div div:nth-child(1){
    margin-left: auto;
    margin-right: auto;
   }
   .passwordCheck:first-of-type{
    margin-left: 0 !important;
   }
/* .footer-style{
   
    width: 100%;
   
    text-align: center;
    
} */

.signin-container .MuiPaper-outlined{
    border:none !important
}

/* @media (min-width:1900px){
    
.footer-style{
   
    position: absolute;
    left: 0;
    bottom: 0;
   
    
}
} */
.signin-container .verify_form input{
    margin-left: 0px !important;
}

.otpParagraph_Verification{
    max-width: 400px;
}
.forgotSubText{
    max-width: 290px;
}

.MuiAlert-standardSuccess svg{
    color: #3CC480 !important;
}

.MuiInputLabel-formControl {
    top: 0;
    left: 0;
    position: absolute;
    -webkit-transform: translate(0, 24px) scale(1);
            transform: translate(0, 24px) scale(1);
}


.MuiAlert-standardSuccess{
    margin: 0 auto;
    width: 50%;
}

.MuiAlert-standardError{
    margin: 0 auto;
    width: 50%;
}

.footer-style{
    width: 100%;
    text-align: center;  
}

.signup-container .MuiPaper-outlined{
    border:none !important
}

@media (min-width:1900px){
    
.footer-style{
   
    position: absolute;
    left: 0;
    bottom: 0;
   
    
}
}
.grid-main {
    padding-left: 0;
    padding-right: 30px;
}

.grid-item header {
    padding-right: 0px !important;
}

.FeildsWidth100 .MuiTextField-root {
    width: 100% !important;
}

.log-div .MuiListItemAvatar-root {
    min-width: 50px !important;
}

.log-div span {
    font-size: 14px;
}

.grid-non-editable {
    width: 87% !important;
}

.grid-non-edit-content {
    width: 87% !important;
    /* align-items: center; */
    /* margin: 0 auto; */
    padding: 0 !important;
}

.grid-email-content {
    padding: 0 !important;
    margin-left: -10px;
}

.grid-add-cat {
    padding: 0 !important;
}

.arrow_hide svg {
    display: none;
}

.chip-label {
    font-size: 14px !important;
}

.des_style {
    font-size: 14px;
}

.MuiIconButton-label span {
    font-weight: 500 !important;
}



.form_secton_title {
    font-size: 22px !important;
    color: #5A5A5A !important;
    /* margin-bottom: 26px !important; */
    font-weight: 300 !important;
}

.side-drawer__container .repeats .MuiFormControl-root {
    margin: 0 !important;
}


.date_time_container .MuiSvgIcon-root {
    margin-right: 0 !important;
}


.status {
    text-transform: capitalize;
}

.MuiTableRow-root.sortableHelper .MuiTableCell-root {
    line-height: 0 !important;
}

.tableBody .tableScroll {
    width: 100% !important;
}


.tableBody .tableScroll .status {
    font-size: 14px !important;
}

.MuiTableRow-root.sortableHelper {
    overflow: hidden !important;
}

.MuiTableRow-root.sortableHelper td img {
    margin-top: 0px !important;
}

.MemberTable .invoice {
    font-weight: 700;
}

.DateRange .date-range-label {
    margin: 0;
}

.AccformBox {
    padding-left: 39%;
    position: relative;
    width: 100%;
}

.MuiAccordionSummary-content div:first-child {
    -webkit-flex-basis: 40% !important;
            flex-basis: 40% !important;
}


.switchTop {
    position: absolute;
    top: -56px;
    right: 0;
    width: 61%;
}

.switchTop .MuiTypography-root {
    font-size: 16px;
    letter-spacing: 0.15px;
    font-weight: 400;
    position: relative;
    top: -12px;
}

.switchBox {
    padding-top: 15px;
}

.switchBox .MuiTypography-root {
    font-size: 16px;
    letter-spacing: 0.15px;
    font-weight: 400;
    margin: 0;
}

.switchBox .FormSwitch {
    width: auto;
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
}

.switchBox .FormSwitch div {
    margin: 0 !important;
}

.SpBorder {
    border-bottom: 1px solid #e7e7e7;
    margin-top: 20px;
    margin-bottom: 0;
}

.MuiAccordionSummary-content .passworDots div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.MuiAccordionSummary-content .passworDots div svg {
    margin-right: 8px;
}

.chipBox div {
    width: 100% !important;
}

.Innertable {}
.MediaHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: #ececec 1px solid;
  padding-bottom: 25px;
}

.PageHeadingTopleft {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  font-size: 28px;
  color: #5a5a5a;
  margin-left: 8px;
}

.MediaHeader .MediaAddButton {
  color: #fff;
  background-color: #2a82f6;
  border-radius: 100px;
  padding: 0 30px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.MediaHeader .MediaAddButton:hover {
  background-color: #2a82f6;
}

.mediaLibActionButton {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.MediaButtonHeader {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 20px;
}

.MediaButtonHeader .innercolleft {
  width: 50%;
  float: left;
}

.MediaButtonHeader .innercol {
  float: right;
  margin-top: 24px;
}
.clearfix {
  clear: both;
}

.FilterTab .MuiTabs-indicator {
  display: none;
}

.FilterTab .MuiSvgIcon-root {
  font-size: 30px;
  color: #d3d8da;
}

.FilterTab .Mui-selected svg {
  color: #2a82f6;
}

.FilterTab .MuiTabs-flexContainer .MuiTab-root {
  min-width: 30px;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.FilterTab .MuiTab-wrapper {
  margin: 0 !important;
}

.FilterTab button:first-child {
  border-radius: 4px 0 0 4px;
  border-right: none !important;
}

.FilterTab button:last-child {
  border-radius: 0px 4px 4px 0;
}

.FeatureImgContainer {
  height: 140px;
  width: 250px;
  overflow: hidden;
  position: relative;
  background: #fff;
  border: #e4e8ec 1px solid;
  margin-bottom: 20px;
}

.FeatureImgContainer img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  position: absolute;
  object-fit: cover;
}

.upgradeAlertBox {
  margin-top: 30px;
  margin-bottom: 30px;
}

.FetureImgDetails li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
}
.FetureImgDetails li h3 {
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  margin-right: 5px;
}

.FetureImgDetails li p {
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}

.DeleteImgButton {
  margin-top: 30px;
}
.DeleteImgButton .MuiButton-textSecondary {
  color: #b00020;
  border: #e8e9e9 1px solid;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  padding: 7px 30px;
}

.DeleteImgButton .MuiButton-textSecondary svg {
  font-size: 21px;
  margin-right: 4px;
  position: relative;
  top: -2px;
}

.BootmInfodetailsContainer {
  position: absolute;
  bottom: 148px;
  width: 70%;
}
.BootmInfodetails {
  border-top: #e4e8ec 1px solid;
  border-bottom: #e4e8ec 1px solid;
  padding: 17px 0;
}

.BootmInfodetails li {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.BootmInfodetails li p {
  margin: 0;
  color: #818e94;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif !important;
}

.BootmInfodetails h4 {
  margin: 0;
  color: #4d4d4d;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}
.upgradeNowButton span {
  color: #2a82f6 !important;
}

.upgradeNowButton {
  padding: 0 !important;
  margin-top: 8px !important;
}

.upgradeNowButton:hover {
  background: none;
  text-decoration: underline !important;
  background-color: transparent !important;
}

.ProgressbarCol {
  margin-top: 13px;
}

.ProgressbarCol p {
  margin: 0;
  color: #818e94;
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-top: 12px;
}

.ProgressbarCol .MuiLinearProgress-barColorSecondary {
  background-color: #b00020;
}

.ProgressbarCol .MuiLinearProgress-colorSecondary {
  background-color: #d3d8da;
}

.AddVideoModalButton {
  color: #fff;
  background-color: #2a82f6;
  border-radius: 100px;
  padding: 0 30px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 10px;
}

.YoutubeModaSummary {
  background: #fff;
  max-width: 800px;
  width: 100%;
  height: 500px;
  position: relative;
  left: 0;
  top: 11%;
  display: -webkit-flex;
  display: flex;
  border-radius: 4px;
  margin: 0 auto;
}

.YoutubeModaSummary .ModalLeftCol {
  background: #dfe6e9;
  max-width: 200px;
  width: 100%;
  border-radius: 4px 0 0 4px;
}

.YoutubeModaSummary .ModalLeftCol .ModalLeftColTitle {
  background: #fff;
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}

.TitleIcon {
  margin-right: 5px;
}

.iconYoutube {
  color: #ff0033;
}

.ModalRighttCol {
  padding: 32px 60px;
  width: 100%;
}

.ModalRighttCol h1 {
  margin: 0 !important;
}
.ImportFormContainer {
  text-align: center;
}

.ImportFormContainer li {
  display: block;
  text-align: center;
  color: #4d4d4d;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.ImportFormContainer li svg {
  display: block;
  width: 64px;
  font-size: 64px;
  margin: 0 auto;
  padding-bottom: 12px;
}

.ModaltabsContainer .MuiTabs-flexContainer .MuiTab-root {
  width: 100%;
  margin: 0px !important;
  min-height: auto;
  padding: 10px !important;
  padding-bottom: 7px !important;
  height: 50px;
  text-transform: none;
  text-transform: initial;
  font-size: 16px;
  color: #4D4D4D;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}

.ModaltabsContainer .MuiTabs-flexContainer {
  display: block !important;
}

.ModaltabsContainer .MuiTabs-scroller {
  white-space: normal !important;
  white-space: initial !important;
}

.ModaltabsContainer .MuiTabs-indicator {
  display: none !important;
}

.ModaltabsContainer .MuiTab-wrapper {
  width: 100% !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: initial;
          flex-direction: initial;
  margin: 0 !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}

.ModaltabsContainer .Mui-selected {
  background: #fff;
}

.ModaltabsContainer .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-bottom: 0;
}

.borderBox {
  border: #e4e8ec 2px dashed;
  padding: 40px;
  border-radius: 5px;
  margin-top: 28px;
}

.ImportFormContainer .urlField {
  margin-top: 16px;
}

.ImportFormContainer .urlField .MuiInputBase-input {
  font-size: 20px;
  color: #818e94;
  font-family: "Poppins", sans-serif !important;
  padding: 13px;
  font-weight: 300;
}
.ImportFormContainer .importUrlButton {
  color: #fff;
  background-color: #7266fb;
  border-radius: 4px;
  padding: 0px 12px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 300;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 308px;
  margin: 0 auto;
  margin-top: 16px;
}

.ImportFormContainer .SelectButton {
  color: #fff;
  background-color: #2a82f6;
  border-radius: 4px;
  padding: 0 16px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 300;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  margin-top: 16px;
}

.dragTitle {
  color: #4d4d4d;
  font-family: "Poppins", sans-serif !important;
  font-size: 24px;
  font-weight: 400;
}

.OrTitle {
  color: #00000099;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
}

.ImportBtnArrowIcon {
  margin-left: 6px;
}

.ModalinfoListContainer {
  margin-top: 24px;
}

.ModalinfoListContainer li {
  display: block;
  color: #4d4d4d;
  font-size: 14px;
  margin-bottom: 12px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.ModalinfoListContainer li.Listinfo {
  display: -webkit-flex !important;
  display: flex !important;
  font-weight: 600;
  -webkit-align-items: center;
          align-items: center;
}

.ModalinfoListContainer li.Listinfo svg {
  color: #818e94;
  margin-right: 8px;
  cursor: pointer;
}

.ModalinfoListContainer li .BlueLink {
  color: #2a82f6;
  text-decoration: none;
}

.ModalinfoListContainer li .BlueLink svg {
  color: #2a82f6;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  top: 4px;
}

.ModalinfoListContainer li .DarkLink {
  color: #4d4d4d;
  text-decoration: none;
  font-weight: 600;
}

.ModalinfoListContainer li .DarkLink img {
  position: relative;
  top: 4px;
}

.CopyUrlBtn {
  color: #4d4d4d;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

.MediaModalContainer {
  background: #fff;
  max-width: 800px;
  width: 100%;
  height: 515px;
  position: relative;
  left: 31%;
  top: 11%;
  border-radius: 4px;
}

.MediaModalContainer .ModalHeader {
  display: -webkit-flex;
  display: flex;
  border-bottom: #e8e9e9 1px solid;
  height: 60px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 16px;
}

.MediaModalContainer .ModalHeader h1 {
  color: #5a5a5a;
  font-size: 24px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  margin: 0;
}

.MediaModalContainer .ModalFooter {
  background: #dfe6e9;
  height: 72px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 16px;
  position: absolute;
  bottom: 0;
  width: 96%;
  left: 0;
  border-radius: 0 0 4px 4px;
}

.ModalFooter .importUrlButton {
  color: #fff;
  background-color: #7266fb;
  border-radius: 4px;
  padding: 0 20px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 400;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 8px;
}

.ModalFooter .ImportBtnArrowIcon {
  margin-left: 6px;
}

.LeftButtonCol {
  display: -webkit-flex;
  display: flex;
}

.MediaAddButton {
  color: #fff;
  background-color: #818e94;
  border-radius: 4px;
  padding: 0 20px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 400;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  font-weight: 300;
}

.ModalCloseIcon {
  color: #818e94;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
}

.LibModalBody {
  padding: 20px 28px;
  overflow-y: auto;
  height: 266px;
  padding-top: 0 !important;
}

.MediaLibraryContainerMain {
  padding-top: 100px;
  padding-left: 70px;
  padding-right: 80px;
}

.youtubeVideoBox {
  position: absolute;
  width: 90%;
  left: 0;
  top: 0;
  background: #f7f8fa;
  height: 90%;
  text-align: left;
  padding: 6px;
}

.youtubeVideoBox svg {
}

.youtubeVideoBox p {
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  margin-top: 5px;
  line-height: 14px;
  text-transform: none;
  text-transform: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.MediaButtonHeader .MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transform: translate(0, 5px) scale(1) !important;
          transform: translate(0, 5px) scale(1) !important;
  z-index: 999;

}

.MediaButtonModalHeader {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 17px;
  padding-right: 20px;
  padding-bottom: 8px;
  margin-top: -8px;
}

.MediaImgListing .mediaImagePreview {
  display: -webkit-flex;
  display: flex;
  margin-top: 16px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.MediaImgListing .MediaImgListingLeft {
  display: -webkit-flex;
  display: flex;
}

.MediaImgListing .MediaImgListingLeftImg {
  object-fit: cover;
  width: 46px;
  height: 46px;
}

.MediaImgListing .MediaImgListingLeftInfo {
  margin-left: 15px;
}

.MediaImgListing .MediaImgListingLeftInfo p {
  color: #4d4d4d;
  margin: 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.MediaImgListing .MediaImgListingLeftInfo .failing {
  color: #b00020;
  margin: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  display: block;
}

.MediaImgListing .MediaImgListingLeftInfo .ImgSize{
  
  color: #818E94;
  margin: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  display: block;
}

.iconDelete {
  color: #818e94;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.DoneButtonContainer {
  position: absolute;
  width: 96%;
  background: #fff;
  left: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 16px;
  border-top: #e8e9e9 1px solid;
  -webkit-align-items: center;
          align-items: center;
}

.DoneButtonContainer .DoneButton {
  color: #fff;
  background-color: #2a82f6;
  border-radius: 4px;
  padding: 0 16px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 400;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.imgBox {
  position: relative;
}

.imgBox .checkIcon {
  position: absolute;
  background: #3cc480;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  left: 15px;
  top: 14px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.imgBox .checkIcon svg {
  font-size: 12px;
}

.MediaLibraryContainerMain .MuiFormControl-root {
  margin: 0 !important;
}

.MuiButtonBase-root .fileIcon {
  margin-right: 10px;
  opacity: 0.9;
}

.MuiButtonBase-root .iconYoutube {
  margin-right: 10px;
}

.AddMoerStorageContainer{
  text-align: center;
}

.AddMoerStorageContainer .warningIcon{
color: #B00020;
font-size: 53px;
}

.dragSubText{
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #4D4D4D;
}

.LinkText{
  color: #2A82F6;
}

.ImgUploadingPrograss {
  position: absolute;
  top: 0;
  left: 0;
  width: 81%;
  background: #F7F8FA;
  height: 100%;
  border: #E4E8EC 1px solid;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.ImgUploadingPrograss .MuiBox-root{
  display: block;
  width: 100%;
}

.ImgUploadingPrograss .MuiLinearProgress-barColorPrimary{
  background-color:#2A82F6;
}

.ImgUploadingPrograss .MuiLinearProgress-root {
  height: 4px;
  overflow: hidden;
  position: relative;
  border-radius: 100px;
}

.ImgUploadingPrograss .MuiTypography-root{
  font-size: 12px;
  color: #818E94;
  margin-top: 4px;
}

.MediaLibraryContainerMain .alert-system{
  width: 100%;
}

.MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 77%;
    -webkit-flex-basis: 77%;
            flex-basis: 77%;
}


@media (max-width: 1830px) {
  .MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 75%;
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
}
}

@media (max-width: 1700px) {
  .MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 70%;
    -webkit-flex-basis: 70%;
            flex-basis: 70%;
}
}

@media (max-width: 1636px) {
  .FeatureImgContainer {
    width: 100%;
  }
}

@media (max-width: 1520px) {
  .MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 68%;
    -webkit-flex-basis: 68%;
            flex-basis: 68%;
}
}

@media (max-width: 1450px) {
  .MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 65%;
    -webkit-flex-basis: 65%;
            flex-basis: 65%;
}
}


.org-accordian .sub-heading svg {
  fill: #fff;
  background: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
}

.org-accordian .sub-heading {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .MuiAccordionSummary-content {
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .MuiIconButton-label {
  color: #2a82f6;
  font-size: 14px;
  font-weight: 500;
}

.org-accordian .MuiAccordionSummary-expandIcon {
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}

.org-accordian-single-tab.Mui-expanded .MuiAccordionSummary-root {
  display: block;
}

.org-accordian-single-tab.Mui-expanded
  .MuiAccordionSummary-content.Mui-expanded {
  display: block;
}

.org-accordian-single-tab.Mui-expanded
  .MuiAccordionSummary-content.Mui-expanded
  .sub-heading {
  display: none;
}

.org-accordian-single-tab.Mui-expanded .MuiAccordionSummary-expandIcon {
  display: none;
}


.subtable
  .org-accordian
  .org-accordian-single-tab.Mui-expanded
  p.MuiTypography-body1 {
  position: relative;
  top: 10px;
}

.org-accordian .cloud-icon svg {
  fill: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
}

.second-row .photo-icon {
  background: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.org-accordian .photo-icon svg {
  fill: #fff;
  color: #fff;
  font-size: 28px;
}

.second-row {
  margin-top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.second-row .photo-icon {
  line-height: 10px;
}

.second-row .logo-field {
  color: #2a82f6;
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.second-row .cloud-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.second-row .logo-label {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 6px;
}

.second-row .first-field {
  width: 49%;
}

.second-row .upload-field {
  width: 100%;
}

.org-accordian .org-accordian-single-tab .summary-accordian-divider {
  width: 133.5%;
  position: absolute;
  right: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.org-accordian .org-accordian-single-tab .summary-accordian-actions {
  position: absolute;
  right: 0;
  margin-top: 15px;
}

.org-accordian
  .org-accordian-single-tab
  .summary-accordian-actions
  .cancel-btn {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab .sub-heading.link {
  color: #818e94;
}

.org-accordian .org-accordian-single-tab .linkBoxContainer {
  background-color: #f8f8f8;
  margin-left: 0;
  margin-right: 0;
  padding: 13px 10px;
  display: -webkit-flex;
  display: flex;
  color: #bcc0c2;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  margin-top: 15px;
  width: 60%;
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .org-accordian-single-tab .linkBoxContent {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .org-accordian-single-tab .linkBoxContent .linkAvailable {
  color: #4d4d4d;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

.org-accordian
  .org-accordian-single-tab
  .project-page-details
  .projectPageText1 {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
  width: 65%;
}

.org-accordian
  .org-accordian-single-tab
  .project-page-details
  .projectPageText2 {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
}

.org-accordian
  .org-accordian-single-tab
  .linkBoxContainer
  .input_container.textfield {
  margin-left: 8px;
}

.org-accordian
  .org-accordian-single-tab
  .linkBoxContainer
  .input_container.textfield
  input {
  height: 36px;
}

.org-accordian .org-accordian-single-tab .project-page-details {
  display: block;
}

.org-accordian .org-accordian-single-tab .summary-accordian-actions .save-btn {
  color: #2490f9;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  input {
  border-radius: 50%;
  width: 36px;
  overflow: hidden;
  height: 36px;
  margin-right: 3px;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  input::-webkit-color-swatch-wrapper {
  margin: 0 auto;
  padding: 0;
  border-radius: 50px;
  width: 50px;
  position: relative;
  left: -5px;
  height: 40px;
  bottom: 2px;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  .colorCode {
  color: #4d4d4d;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .mainbannerPara {
  background-color: #f9ebed;
  color: #c44058;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding: 5px 5px;
  width: 60px;
  font-weight: bold;
  border-radius: 100px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.AddedChip {
  background-color: #effbf5;
  color: #3cc480;
  font-size: 12px;
  padding: 5px 5px;
  font-weight: bold;
  border-radius: 100px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 68px;
  -webkit-align-items: center;
          align-items: center;
}

.AddedChip svg {
  font-size: 17px;
  margin-right: 2px;
}
.infoIcon {
  color: #d3d8da;
  margin-left: 8px;
}

.org-accordian .org-accordian-single-tab .pageTitleContent {
  width: 80%;
  display: block;
}

.org-accordian .org-accordian-single-tab .pageTitleContent .mainBannerInput {
  width: 100%;
}

.org-accordian .org-accordian-single-tab .supportAttention {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
  margin-top: 18px;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab ul {
  padding-left: 16px;
  margin-top: 0;
}

.org-accordian .org-accordian-single-tab ul li {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab ul li::before {
  content: "\2022";
  color: #2a8853;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.org-accordian .org-accordian-single-tab .mainPageContent {
  display: block;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .mainPageContent .photo-icon {
  line-height: 1;
}

.org-accordian .org-accordian-single-tab .mainPageContent .cloud-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .org-accordian-single-tab .mainPageContent .mainPagePictureText {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab .mainPageContent .logo-field {
  color: #2a82f6;
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  padding: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer !important;
}

.org-accordian .org-accordian-single-tab .donationPageLinkT {
  width: 80%;
}

.org-accordian .org-accordian-single-tab .donationText {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab .projectPageText4 {
  color: #818e94;
  font-size: 14px;
  margin-top: 30px;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .projectPageText4 {
  color: #818e94;
  font-size: 14px;
  margin-top: 5px;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .amountInput {
  width: 30%;
  margin-top: 20px;
}

.org-accordian .org-accordian-single-tab .amountInput1 {
  width: 68%;
  margin-top: 20px;
  margin-left: 5px;
}

.org-accordian .org-accordian-single-tab .amountInput p {
  top: 0px !important;
}

.org-accordian .org-accordian-single-tab .amountLabel {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect {
  display: -webkit-flex;
  display: flex;
  width: 80%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect .delete-icon svg {
  margin: 0 7px;
  color: #859198;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect .add-icon svg {
  margin: 0 7px;
  color: green;
}

.add-approach-icon {
  top: -5px;
}

.add-approach-icon svg {
  color: green;
}

.org-accordian .org-accordian-single-tab p.makeStyles-heading-2 {
  max-width: 240px;
}

.delete-logo-icon svg {
  fill: #859198;
  color: #859198;
  position: relative;
  top: 3px;
  cursor: pointer;
}

.uploaded-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.uploaded-img-container {
  color: #4d4d4d;
  display: -webkit-flex;
  display: flex;
  overflow-x: hidden;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 10px;
}

.uploaded-img-container img {
  margin-right: 10px;
}

.sub-heading .phase-icon svg {
  fill: #2a82f6;
  background: transparent;
  padding: 2px;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: 2px;
}

.sub-heading .visibility-section-icon svg {
  fill: #00000033;
  background: transparent;
  padding: 0px;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: 2px;
}

.linkAvailable .available-icon svg {
  position: relative;
  top: 7px;
  fill: green;
}

.linkAvailable .not-available-icon svg {
  position: relative;
  top: 7px;
  fill: red;
}

.org-accordian
  .org-accordian-single-tab
  .MuiAccordionSummary-content
  p:first-child {
  width: 220px;
  max-width: 220px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #4d4d4d;
}

.org-accordian
  .org-accordian-single-tab
  .amountInput
  .MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart {
  padding-left: 0px !important;
}

.org-accordian
  .org-accordian-single-tab
  .amountInput
  .MuiInputAdornment-root.MuiInputAdornment-positionStart
  p {
  height: 56px;
  line-height: 56px;
  width: 45px;
  text-align: center;
  border-right: 1px solid #e4e8ec;
  font-size: 16px;
}

.disableBtnItem {
  color: lightgray;
}

.logo-field .upload-logo-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.logo-field .upload-color-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.MuiMenu-list .MuiListItem-button {
  padding-left: 10px !important;
}

.donation-approach-label {
  color: #818e94;
  font-size: 12px;
}

.priceLabelSelect svg {
  cursor: pointer;
}

.edit_profile_photo{
  text-align: center;
  margin-top: 110px;
}

.edit_profile_photo div{
  width: auto !important;
  height: auto !important;
}

.edit_profile_photo div img{
  width: 170px !important;
}

.border_line{
  border-bottom: #E8E9E9 1px solid;
}

.page_footer {
  border-top: #E8E9E9 1px solid;
  height: 52px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.footer_btn button{
  background: transparent !important;
  color: #2490F9 !important;
  padding: 0 !important;
  margin-left:15px;
}

.footer_btn button:hover{
  background: transparent !important;
  color: #2490F9 !important;
}

.adminProfile_container{
  padding-bottom: 30px;
}

.adminProfile_container .base-text-field .MuiInputAdornment-root svg{
  display: none;
}

.MuiFormHelperText-root {
    color: #818e94 !important;
}

.remove_photo{
  text-transform: uppercase;
}

.adminProfile_container .MuiAvatar-circle{
    height: 170px !important;
    width: 170px !important;
}

.adminProfile_container .MuiAvatar-fallback {
  width: 75% !important;
  height: 75% !important;
}

.card_details_table .cron_icon{
  background: #F4F9EC !important;
}

.HelperText{
  color: #818E94;
  font-size: 14px;
  padding-top: 8px;

}




.DeleteAccountContainer{
  max-width: 440px;
}

.DeleteAccountContainer .DeleteAccountSubTitle{
  font-size: 22px;
  color: #5A5A5A;
  font-weight: 300;
}

.DeleteAccountContainer .UserIconContainer{
  margin-top: 21px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
}

.DeleteAccountContainer .UserIconContainer .UserIcon{
  width: 50px;
  height: 50px;
  background: 0% 0% no-repeat padding-box padding-box rgb(114, 102, 251);
  border-radius: 50px;
  display: -webkit-flex;
  display: flex;
  margin-right: 16px;
  -webkit-align-items: center;
          align-items: center;
}

.DeleteAccountContainer .UserIconContainer .UserIcon p {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.51px;
  color: rgb(255, 255, 255);
  margin: 0px auto;
  
}

.DeleteAccountContainer .UserIconContainer .UserDtlInfoCol{
  font-size: 14px;
  color: #4D4D4D;
  font-weight: 600;
}

.DeleteAccountContainer .UserIconContainer .UserDtlInfoColSubTitle{
  font-size: 12px;
  color: #818E94;
  font-weight: 400;
  margin-top: 4px;
}

.DeleteAccountContainer .DeleteAccountInfoList{
  margin-top: 41px;
}

.DeleteAccountContainer .DeleteAccountInfoList ul{
  padding: 0;
  margin-top: 33px;
}

.DeleteAccountContainer .DeleteAccountInfoList ul li{
  font-size: 16px;
  color: #4D4D4D;
  font-weight: 400;
  margin-top: 4px;
  font-family: 'Open Sans', sans-serif !important;
  margin-bottom: 20px;
  line-height: 22px;
}

.DeleteAccountContainer .DeleteAccountInfoList ul li span{
  font-weight: 600;

}

.DeleteAccountContainer .DeleteAccountInfoList ul li .infoIconS {
  color: #818E94;
  font-size: 20px;
  position: relative;
  top: 5px;
}

.PageTitleContainer{
  background-color:#F7F8FA;
  height: 94px;
  margin-bottom: 41px;
  position: relative;

  
}

.PageTitleContainer .title_top{
  position: relative;
  top: 27px;

}

.PageContainer{
  max-width: 970px;
  margin: 0 auto;
}

.PageBottomContainer{
  border-top:#D3D8DA 2px dashed;
}

.buttonRed .MuiButton-containedPrimary {
  color: #fff;
  background-color: #B00020 !important;
}








.app_card_container{
  margin-left: -10px;
  margin-right: -10px;
  max-width: 1920px;
  margin-top: 20px;
}


.app_card_container .apps_card{
  margin: 10px;
  float: left;
}

.clearfix{
  clear: both;
}

.chip_tab_container .MuiChip-root{
  margin-right: 10px;
  background: #fff;
  border: #E8E9E9 1px solid;
  color: #818E94;
  font-size: 14px;
  text-transform: uppercase;
}

.chip_tab_container .active{
  background: #007BF2 !important;
  border: #E8E9E9 1px solid;
  color: #fff;
}

.chip_tab_container .active:hover {
  background: #007BF2 !important;
  border: #E8E9E9 1px solid;
  color: #fff;
}

.AppPageContainer{
  padding-top: 30px !important;
  margin-top: 30px;
}

.your_cart_title{
  font-size: 16px;
  color: #4C5E67;
  font-weight: 400 !important;
  font-family: 'Poppins', sans-serif;
  margin-bottom:8px;
}

.AppDetails_container{
  max-width: 80%;
  margin: 0 auto;
}

.PageHeaderContainer {
  border-bottom: #F7F8FA 1px solid;
  height: 91px;
  width: 91%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.PageHeaderContainer .backBtn{
  margin-top:0 !important;
}


.paymentSuccessContainer{
  padding-top:50px;
}

.paymentSuccessContainer .MuiPaper-outlined{
  border: none !important;
}

.paymentSuccessContainer .secured_text{
  display: none;
}

.paymentSuccessContainer .MuiTypography-body1 {
  font-size: 14px !important;
  max-width: 350px;
  margin-bottom: 20px !important;
}


.greyHeader{
  background-color: #F7F8FA;
  min-height: 94px;
  text-align: center;
}

.greyHeader .checkTitle {
  color: #4D4D4D;
  font-size: 28px;
  font-weight: 300;
  padding-top: 27px;
}

.number_select {
  margin-top: 16px !important;
}

.midContainer{
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
}

.midContainer .backBtn{
  left:0px !important;
}

.CheckOutFormContainer{
  padding-bottom:70px !important;
  padding-top:30px;

}

.app_price_section .price_left_col .p_amount{
  font-weight: 600;
}

.MuiSwitch-input {
  height:40px !important;
  }
  
  .app_details_container .MuiSwitch-thumb {
    top: 9px !important;
    position: relative !important;
    color:#fff !important;
  }

  .app_details_container .MuiTouchRipple-root {
    display: none !important;
  }
  
  .app_details_container .MuiSwitch-track {
  position: absolute !important;
      height: 14px !important;
      top: 5px !important;
      border-radius: 10px !important;
      background-color: #00000036 !important;
  }
  
  .app_details_container .MuiSwitch-root {
  width: 40px !important;
      padding: 0 !important;
      height: 25px !important;
  }
  
  .app_details_container .Mui-checked .MuiSwitch-thumb {
  color: #7266fb !important;
  }

  .app_details_container .PricingContainer .prs_switch .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
    background-color: #7266fb !important;
  }

  .app_details_container .PricingContainer .prs_switch {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .BaseCartButtonContainer .Cartdropdown {
    position: absolute;
    right: 37px;
    top: 76px;
}

.cart-table-data .data{
  text-transform: capitalize;
}


.CheckoutPaymentForm .MuiInputLabel-formControl {
  top: -3px;
  left: 16px;
  position: absolute;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
  z-index: 999;
}

.CheckoutPaymentForm .MuiInputLabel-shrink {
  -webkit-transform: translate(0px, 1px) scale(0.75) !important;
          transform: translate(0px, 1px) scale(0.75) !important;
  top: -7px !important;
  left: 14px !important;
}

.order-summary-title-class{
  display: -webkit-flex !important;
  display: flex !important;
}

/* .form_button_box button {
  background-color: #0590FA !important;
} */
.Messages{
    width: 96%;
    margin-left: 13px;
}

.Notifications{
    width: 96%;
    margin-left: 13px;
}
.contact-us-backbutton{
    position: 'relative';
    height: '65px' !important;
}

.uploadContainer{
  display: -webkit-flex;
  display: flex;
}

.uploadContainer .uploadcol{
  margin-right: 20px;
}
.uploadContainer .iconUploadField{
  display: none;
}

.uploadContainer .uploadcol .Uploadbtn{
  color: #2A82F6;
  font-size: 14px;
  font-weight: 500;
}

.uploadContainer .uploadcol .UploadbtnText {
  color: #2A82F6;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  left: -10px;
  top: 2px;
}

.ListingTableContainer{
  margin-top: 60px;
}

.BuildProjectFieldContainer{
  max-width: 690px;
  margin: 0 auto;
  padding-top: 30px;
  display: -webkit-flex;
  display: flex;
}

.BuildProjectFieldContainer .BuildProjectField{
  max-width: 550px;
  min-width: 550px;
}


.BuildProjectFieldContainer .BuildProjectButton .MuiButtonBase-root {
  border-radius: 0 4px 4px 0 !important;
  height: 56px !important;
  margin: 0 !important;
}

.MuiIconButton-label .MuiSvgIcon-root {
    color: #4D4D4D;
}

.Mui-checked .MuiSvgIcon-root{
    color: #3CC480 !important;
}

.MediaHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: #ececec 1px solid;
  padding-bottom: 25px;
}

.PageHeadingTopleft {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  font-size: 28px;
  color: #5a5a5a;
  margin-left: 8px;
}

.MediaHeader .MediaAddButton {
  color: #fff;
  background-color: #2a82f6;
  border-radius: 100px;
  padding: 0 30px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.MediaHeader .MediaAddButton:hover {
  background-color: #2a82f6;
}

.mediaLibActionButton {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.MediaButtonHeader {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 20px;
}

.MediaButtonHeader .innercolleft {
  width: 50%;
  float: left;
}

.MediaButtonHeader .innercol {
  float: right;
  margin-top: 24px;
}
.clearfix {
  clear: both;
}

.FilterTab .MuiTabs-indicator {
  display: none;
}

.FilterTab .MuiSvgIcon-root {
  font-size: 30px;
  color: #d3d8da;
}

.FilterTab .Mui-selected svg {
  color: #2a82f6;
}

.FilterTab .MuiTabs-flexContainer .MuiTab-root {
  min-width: 30px;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.FilterTab .MuiTab-wrapper {
  margin: 0 !important;
}

.FilterTab button:first-child {
  border-radius: 4px 0 0 4px;
  border-right: none !important;
}

.FilterTab button:last-child {
  border-radius: 0px 4px 4px 0;
}

.mdLibSideBar {
  background: #f7f8fa;
  height: 100%;
  position: fixed;
  top: 0;
  padding:110px 30px 20px 30px;
  right: 0;
  width: 330px;
}

.FeatureImgContainer {
  height: 140px;
  width: 250px;
  overflow: hidden;
  position: relative;
  background: #fff;
  border: #e4e8ec 1px solid;
  margin-bottom: 20px;
}

.FeatureImgContainer img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  position: absolute;
  object-fit: cover;
}

.upgradeAlertBox {
  margin-top: 30px;
  margin-bottom: 30px;
}

.FetureImgDetails li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
}
.FetureImgDetails li h3 {
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  margin-right: 5px;
}

.FetureImgDetails li p {
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}

.DeleteImgButton {
  margin-top: 30px;
}
.DeleteImgButton .MuiButton-textSecondary {
  color: #b00020;
  border: #e8e9e9 1px solid;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  padding: 7px 30px;
}

.DeleteImgButton .MuiButton-textSecondary svg {
  font-size: 21px;
  margin-right: 4px;
  position: relative;
  top: -2px;
}

.BootmInfodetailsContainer {
  position: absolute;
  bottom: 148px;
  width: 70%;
}
.BootmInfodetails {
  border-top: #e4e8ec 1px solid;
  border-bottom: #e4e8ec 1px solid;
  padding: 17px 0;
}

.BootmInfodetails li {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.BootmInfodetails li p {
  margin: 0;
  color: #818e94;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif !important;
}

.BootmInfodetails h4 {
  margin: 0;
  color: #4d4d4d;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}
.upgradeNowButton span {
  color: #2a82f6 !important;
}

.upgradeNowButton {
  padding: 0 !important;
  margin-top: 8px !important;
}

.upgradeNowButton:hover {
  background: none;
  text-decoration: underline !important;
  background-color: transparent !important;
}

.ProgressbarCol {
  margin-top: 13px;
}

.ProgressbarCol p {
  margin: 0;
  color: #818e94;
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  margin-top: 12px;
}

.ProgressbarCol .MuiLinearProgress-barColorSecondary {
  background-color: #b00020;
}

.ProgressbarCol .MuiLinearProgress-colorSecondary {
  background-color: #d3d8da;
}

.AddVideoModalButton {
  color: #fff;
  background-color: #2a82f6;
  border-radius: 100px;
  padding: 0 30px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 10px;
}

.YoutubeModaSummary {
  background: #fff;
  max-width: 800px;
  width: 100%;
  height: 500px;
  position: relative;
  left: 0;
  top: 11%;
  display: -webkit-flex;
  display: flex;
  border-radius: 4px;
  margin: 0 auto;
}

.YoutubeModaSummary .ModalLeftCol {
  background: #dfe6e9;
  max-width: 200px;
  width: 100%;
  border-radius: 4px 0 0 4px;
}

.YoutubeModaSummary .ModalLeftCol .ModalLeftColTitle {
  background: #fff;
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}

.TitleIcon {
  margin-right: 5px;
}

.iconYoutube {
  color: #ff0033;
}

.ModalRighttCol {
  padding: 32px 60px;
  width: 100%;
}

.ModalRighttCol h1 {
  margin: 0 !important;
}
.ImportFormContainer {
  text-align: center;
}

.ImportFormContainer li {
  display: block;
  text-align: center;
  color: #4d4d4d;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.ImportFormContainer li svg {
  display: block;
  width: 64px;
  font-size: 64px;
  margin: 0 auto;
  padding-bottom: 12px;
}

.ModaltabsContainer .MuiTabs-flexContainer .MuiTab-root {
  width: 100%;
  margin: 0px !important;
  min-height: auto;
  padding: 10px !important;
  padding-bottom: 7px !important;
  height: 50px;
  text-transform: none;
  text-transform: initial;
  font-size: 16px;
  color: #4D4D4D;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}

.ModaltabsContainer .MuiTabs-flexContainer {
  display: block !important;
}

.ModaltabsContainer .MuiTabs-scroller {
  white-space: normal !important;
  white-space: initial !important;
}

.ModaltabsContainer .MuiTabs-indicator {
  display: none !important;
}

.ModaltabsContainer .MuiTab-wrapper {
  width: 100% !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: initial;
          flex-direction: initial;
  margin: 0 !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}

.ModaltabsContainer .Mui-selected {
  background: #fff;
}

.ModaltabsContainer .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-bottom: 0;
}

.borderBox {
  border: #e4e8ec 2px dashed;
  padding: 40px;
  border-radius: 5px;
  margin-top: 28px;
}

.ImportFormContainer .urlField {
  margin-top: 16px;
}

.ImportFormContainer .urlField .MuiInputBase-input {
  font-size: 20px;
  color: #818e94;
  font-family: "Poppins", sans-serif !important;
  padding: 13px;
  font-weight: 300;
}
 .importUrlButton {
  color: #fff;
  background-color: #7266fb !important;
  border-radius: 4px;
  padding: 0px 12px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 300;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 308px;
  margin: 0 auto;
  margin-top: 16px;
}

.SelectButton {
  color: #fff;
  background-color: #2a82f6 !important;
  border-radius: 4px;
  padding: 0 16px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 300;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  margin-top: 16px;
}

.dragTitle {
  color: #4d4d4d;
  font-family: "Poppins", sans-serif !important;
  font-size: 24px;
  font-weight: 400;
}

.OrTitle {
  color: #00000099;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
}

.ImportBtnArrowIcon {
  margin-left: 6px;
}

.ModalinfoListContainer {
  margin-top: 24px;
}

.ModalinfoListContainer li {
  display: block;
  color: #4d4d4d;
  font-size: 14px;
  margin-bottom: 12px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.ModalinfoListContainer li.Listinfo {
  display: -webkit-flex !important;
  display: flex !important;
  font-weight: 600;
  -webkit-align-items: center;
          align-items: center;
}

.ModalinfoListContainer li.Listinfo svg {
  color: #818e94;
  margin-right: 8px;
  cursor: pointer;
}

.ModalinfoListContainer li .BlueLink {
  color: #2a82f6;
  text-decoration: none;
}

.ModalinfoListContainer li .BlueLink svg {
  color: #2a82f6;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  top: 4px;
}

.ModalinfoListContainer li .DarkLink {
  color: #4d4d4d;
  text-decoration: none;
  font-weight: 600;
}

.ModalinfoListContainer li .DarkLink img {
  position: relative;
  top: 4px;
}

.CopyUrlBtn {
  color: #4d4d4d;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

.MediaModalContainer {
  background: #fff;
  max-width: 800px;
  width: 100%;
  height: 515px;
  position: relative;
  left: 31%;
  top: 11%;
  border-radius: 4px;
}

.MediaModalContainer .ModalHeader {
  display: -webkit-flex;
  display: flex;
  border-bottom: #e8e9e9 1px solid;
  height: 60px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 16px;
}

.MediaModalContainer .ModalHeader h1 {
  color: #5a5a5a;
  font-size: 24px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  margin: 0;
}

.MediaModalContainer .ModalFooter {
  background: #dfe6e9;
  height: 72px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 16px;
  position: absolute;
  bottom: 0;
  width: 96%;
  left: 0;
  border-radius: 0 0 4px 4px;
}

.ModalFooter .importUrlButton {
  color: #fff;
  background-color: #7266fb;
  border-radius: 4px;
  padding: 0 20px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 400;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 8px;
}

.ModalFooter .ImportBtnArrowIcon {
  margin-left: 6px;
}

.LeftButtonCol {
  display: -webkit-flex;
  display: flex;
}

.MediaAddButton {
  color: #fff;
  background-color: #818e94;
  border-radius: 4px;
  padding: 0 20px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 400;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 auto;
  font-weight: 300;
}

.ModalCloseIcon {
  color: #818e94;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
}

.LibModalBody {
  padding: 20px 28px;
  overflow-y: auto;
  height: 266px;
  padding-top: 0 !important;
}

.MediaLibraryContainerMain {
  padding-top: 100px;
  padding-left: 70px;
  padding-right: 80px;
}

.MediaLibContainerinner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.MediaLibRightDrawerContainer{
  width: 34%;
}

@media (min-width: 1920px) {
  .MediaLibLeftColm {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.MediaLibRightDrawerContainer {
  width: 340px;
}
}

@media (max-width: 1890px) {
  .MediaLibLeftColm {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.MediaLibRightDrawerContainer {
  width: 340px;
}
}


.youtubeVideoBox {
  position: absolute;
  width: 90%;
  left: 0;
  top: 0;
  background: #f7f8fa;
  height: 90%;
  text-align: left;
  padding: 6px;
}

.youtubeVideoBox svg {
}

.youtubeVideoBox p {
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  margin-top: 5px;
  line-height: 14px;
  text-transform: none;
  text-transform: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.MediaButtonHeader .MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transform: translate(0, 5px) scale(1) !important;
          transform: translate(0, 5px) scale(1) !important;
  z-index: 999;
}

.MediaButtonModalHeader {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 17px;
  padding-right: 20px;
  padding-bottom: 8px;
  margin-top: -8px;
}

.MediaImgListing .mediaImagePreview {
  display: -webkit-flex;
  display: flex;
  margin-top: 16px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.MediaImgListing .MediaImgListingLeft {
  display: -webkit-flex;
  display: flex;
}

.MediaImgListing .MediaImgListingLeftImg {
  object-fit: cover;
  width: 46px;
  height: 46px;
}

.MediaImgListing .MediaImgListingLeftInfo {
  margin-left: 15px;
}

.MediaImgListing .MediaImgListingLeftInfo p {
  color: #4d4d4d;
  margin: 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.MediaImgListing .MediaImgListingLeftInfo .failing {
  color: #b00020;
  margin: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  display: block;
}

.MediaImgListing .MediaImgListingLeftInfo .ImgSize{
  
  color: #818E94;
  margin: 0;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  display: block;
}

.iconDelete {
  color: #818e94;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.DoneButtonContainer {
  position: absolute;
  width: 96%;
  background: #fff;
  left: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 16px;
  border-top: #e8e9e9 1px solid;
  -webkit-align-items: center;
          align-items: center;
}

.DoneButtonContainer .DoneButton {
  color: #fff;
  background-color: #2a82f6;
  border-radius: 4px;
  padding: 0 16px;
  font-family: "Poppins", sans-serif !important;
  font-size: 23px;
  font-weight: 400;
  height: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.imgBox {
  position: relative;
}

.imgBox .checkIcon {
  position: absolute;
  background: #3cc480;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  left: 15px;
  top: 14px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.imgBox .checkIcon svg {
  font-size: 12px;
}

.MediaLibraryContainerMain .MuiFormControl-root {
  margin: 0 !important;
}

.MuiButtonBase-root .fileIcon {
  margin-right: 10px;
  opacity: 0.9;
}

.MuiButtonBase-root .iconYoutube {
  margin-right: 10px;
}

.AddMoerStorageContainer {
  text-align: center;
  max-width: 290px;
  margin: 0 auto;
  margin-top: 80px;
}

.AddMoerStorageContainer .warningIcon{
color: #B00020;
font-size: 53px;
}

.dragSubText{
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #4D4D4D;
  margin-bottom: 24px;
}

.LinkText{
  color: #2A82F6;
}

.ImgUploadingPrograss {
  position: absolute;
  top: 0;
  left: 0;
  width: 81%;
  background: #F7F8FA;
  height: 100%;
  border: #E4E8EC 1px solid;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.ImgUploadingPrograss .MuiBox-root{
  display: block;
  width: 100%;
}

.ImgUploadingPrograss .MuiLinearProgress-barColorPrimary{
  background-color:#2A82F6;
}

.ImgUploadingPrograss .MuiLinearProgress-root {
  height: 4px;
  overflow: hidden;
  position: relative;
  border-radius: 100px;
}

.ImgUploadingPrograss .MuiTypography-root{
  font-size: 12px;
  color: #818E94;
  margin-top: 4px;
}

.MediaLibraryContainerMain .alert-system{
  width: 100%;
}

.MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 77%;
    -webkit-flex-basis: 77%;
            flex-basis: 77%;
}

.alert-message-content{
  font-family: "Poppins", sans-serif !important;
}


@media (max-width: 1830px) {
  .MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 75%;
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
}
}

@media (max-width: 1700px) {
  .MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 70%;
    -webkit-flex-basis: 70%;
            flex-basis: 70%;
}
}

@media (max-width: 1636px) {
  .FeatureImgContainer {
    width: 100%;
  }
}

@media (max-width: 1520px) {
  .MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 68%;
    -webkit-flex-basis: 68%;
            flex-basis: 68%;
}
}

@media (max-width: 1450px) {
  .MediaLibraryContainerMain .MuiGrid-grid-md-9{
    max-width: 65%;
    -webkit-flex-basis: 65%;
            flex-basis: 65%;
}
}


.org-accordian .sub-heading svg {
  fill: #fff;
  background: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
}

.org-accordian .sub-heading {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .MuiAccordionSummary-content {
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .MuiIconButton-label {
  color: #2a82f6;
  font-size: 14px;
  font-weight: 500;
}

.org-accordian .MuiAccordionSummary-expandIcon {
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
}

.org-accordian-single-tab.Mui-expanded .MuiAccordionSummary-root {
  display: block;
}

.org-accordian-single-tab.Mui-expanded
  .MuiAccordionSummary-content.Mui-expanded {
  display: block;
}

.org-accordian-single-tab.Mui-expanded
  .MuiAccordionSummary-content.Mui-expanded
  .sub-heading {
  display: none;
}

.org-accordian-single-tab.Mui-expanded .MuiAccordionSummary-expandIcon {
  display: none;
}



.subtable
  .org-accordian
  .org-accordian-single-tab.Mui-expanded
  p.MuiTypography-body1 {
  position: relative;
  top: 10px;
}

.org-accordian .cloud-icon svg {
  fill: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
}

.second-row .photo-icon {
  background: #2a82f6;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.org-accordian .photo-icon svg {
  fill: #fff;
  color: #fff;
  font-size: 28px;
}

.second-row {
  margin-top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.second-row .photo-icon {
  line-height: 10px;
}

.second-row .logo-field {
  color: #2a82f6;
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.second-row .cloud-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.second-row .logo-label {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 6px;
}

.second-row .first-field {
  width: 49%;
}

.second-row .upload-field {
  width: 100%;
}

.org-accordian .org-accordian-single-tab .summary-accordian-divider {
  width: 133.5%;
  position: absolute;
  right: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.org-accordian .org-accordian-single-tab .summary-accordian-actions {
  position: absolute;
  right: 0;
  margin-top: 15px;
}

.org-accordian
  .org-accordian-single-tab
  .summary-accordian-actions
  .cancel-btn {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab .sub-heading.link {
  color: #818e94;
}

.org-accordian .org-accordian-single-tab .linkBoxContainer {
  background-color: #f8f8f8;
  margin-left: 0;
  margin-right: 0;
  padding: 13px 10px;
  display: -webkit-flex;
  display: flex;
  color: #bcc0c2;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  margin-top: 15px;
  width: 60%;
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .org-accordian-single-tab .linkBoxContent {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .org-accordian-single-tab .linkBoxContent .linkAvailable {
  color: #4d4d4d;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

.org-accordian
  .org-accordian-single-tab
  .project-page-details
  .projectPageText1 {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
  width: 65%;
}

.org-accordian
  .org-accordian-single-tab
  .project-page-details
  .projectPageText2 {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
}

.org-accordian
  .org-accordian-single-tab
  .linkBoxContainer
  .input_container.textfield {
  margin-left: 8px;
}

.org-accordian
  .org-accordian-single-tab
  .linkBoxContainer
  .input_container.textfield
  input {
  height: 36px;
}

.org-accordian .org-accordian-single-tab .project-page-details {
  display: block;
}

.org-accordian .org-accordian-single-tab .summary-accordian-actions .save-btn {
  color: #2490f9;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  input {
  border-radius: 50%;
  width: 36px;
  overflow: hidden;
  height: 36px;
  margin-right: 3px;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  input::-webkit-color-swatch-wrapper {
  margin: 0 auto;
  padding: 0;
  border-radius: 50px;
  width: 50px;
  position: relative;
  left: -5px;
  height: 40px;
  bottom: 2px;
}

.org-accordian
  .org-accordian-single-tab
  .second-row
  .first-field
  .logo-field
  .colorCode {
  color: #4d4d4d;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .mainbannerPara {
  background-color: #f9ebed;
  color: #c44058;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding: 5px 5px;
  width: 60px;
  font-weight: bold;
  border-radius: 100px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.AddedChip {
  background-color: #effbf5;
  color: #3cc480;
  font-size: 12px;
  padding: 5px 5px;
  font-weight: bold;
  border-radius: 100px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 68px;
  -webkit-align-items: center;
          align-items: center;
}

.AddedChip svg {
  font-size: 17px;
  margin-right: 2px;
}
.infoIcon {
  color: #d3d8da;
  margin-left: 8px;
}

.org-accordian .org-accordian-single-tab .pageTitleContent {
  width: 80%;
  display: block;
}

.org-accordian .org-accordian-single-tab .pageTitleContent .mainBannerInput {
  width: 100%;
}

.org-accordian .org-accordian-single-tab .supportAttention {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  top: 0px !important;
  margin-top: 18px;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab ul {
  padding-left: 16px;
  margin-top: 0;
}

.org-accordian .org-accordian-single-tab ul li {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab ul li::before {
  content: "\2022";
  color: #2a8853;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.org-accordian .org-accordian-single-tab .mainPageContent {
  display: block;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .mainPageContent .photo-icon {
  line-height: 1;
}

.org-accordian .org-accordian-single-tab .mainPageContent .cloud-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.org-accordian .org-accordian-single-tab .mainPageContent .mainPagePictureText {
  color: #818e94;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab .mainPageContent .logo-field {
  color: #2a82f6;
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  padding: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer !important;
}

.org-accordian .org-accordian-single-tab .donationPageLinkT {
  width: 80%;
}

.org-accordian .org-accordian-single-tab .donationText {
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.org-accordian .org-accordian-single-tab .projectPageText4 {
  color: #818e94;
  font-size: 14px;
  margin-top: 30px;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .projectPageText4 {
  color: #818e94;
  font-size: 14px;
  margin-top: 5px;
  width: 80%;
}

.org-accordian .org-accordian-single-tab .amountInput {
  width: 30%;
  margin-top: 20px;
}

.org-accordian .org-accordian-single-tab .amountInput1 {
  width: 68%;
  margin-top: 20px;
  margin-left: 5px;
}

.org-accordian .org-accordian-single-tab .amountInput p {
  top: 0px !important;
}

.org-accordian .org-accordian-single-tab .amountLabel {
  color: #818e94;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect {
  display: -webkit-flex;
  display: flex;
  width: 80%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect .delete-icon svg {
  margin: 0 7px;
  color: #859198;
}

.org-accordian .org-accordian-single-tab .priceLabelSelect .add-icon svg {
  margin: 0 7px;
  color: green;
}

.add-approach-icon {
  top: -5px;
}

.add-approach-icon svg {
  color: green;
}

.org-accordian .org-accordian-single-tab p.makeStyles-heading-2 {
  max-width: 240px;
}

.delete-logo-icon svg {
  fill: #859198;
  color: #859198;
  position: relative;
  top: 3px;
  cursor: pointer;
}

.uploaded-img {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  object-fit: cover;
}

.uploaded-img-container {
  color: #4d4d4d;
  display: -webkit-flex;
  display: flex;
  overflow-x: hidden;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 10px;
}

.uploaded-img-container img {
  margin-right: 10px;
}

.sub-heading .phase-icon svg {
  fill: #2a82f6;
  background: transparent;
  padding: 2px;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: 2px;
}

.sub-heading .visibility-section-icon svg {
  fill: #00000033;
  background: transparent;
  padding: 0px;
  border-radius: 50%;
  margin-right: 4px;
  margin-top: 2px;
}

.linkAvailable .available-icon svg {
  position: relative;
  top: 7px;
  fill: green;
}

.linkAvailable .not-available-icon svg {
  position: relative;
  top: 7px;
  fill: red;
}

.org-accordian
  .org-accordian-single-tab
  .MuiAccordionSummary-content
  p:first-child {
  width: 220px;
  max-width: 220px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #4d4d4d;
}

.org-accordian
  .org-accordian-single-tab
  .amountInput
  .MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart {
  padding-left: 0px !important;
}

.org-accordian
  .org-accordian-single-tab
  .amountInput
  .MuiInputAdornment-root.MuiInputAdornment-positionStart
  p {
  height: 56px;
  line-height: 56px;
  width: 45px;
  text-align: center;
  border-right: 1px solid #e4e8ec;
  font-size: 16px;
}

.disableBtnItem {
  color: lightgray;
}

.logo-field .upload-logo-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.logo-field .upload-color-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.MuiMenu-list .MuiListItem-button {
  padding-left: 10px !important;
}

.donation-approach-label {
  color: #818e94;
  font-size: 12px;
}

.priceLabelSelect svg {
  cursor: pointer;
}



.MuiStepConnector-horizontal{
  width:auto !important
}

.btnWithText{
  position: relative;
    z-index: 999;
    margin-top: 23px;
    margin-left: 60px;
    margin-right: 15px;
}


.page_form_title{
  color: #5A5A5A;
  font-size: 24px !important;
  padding: 0px 30px;
  border-bottom: #E8E9E9 1px solid;
  width: auto !important;
  font-weight: 300 !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 60px;
}

.gridspacing{
  padding: 17px;
}

.FormSwitch{
  width: auto;
}
.no_space{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 0 !important;
}

.sp_top{
  margin-top: 20px !important;
}

.checkBox{
  margin-top: 10px;
}
.icon{
  font-size: 1.2rem !important;
}
/* .web-announcements{
  padding: 0;
  width: 99% !important;
} */

.web-header{
  margin-top: 30px;
}

.grid-add-announc-publish .base-text-field div:nth-child(1){

}

.grid-add-announc-publish div:nth-child(4){
  width: 100% !important;
  margin-top: 40px !important;
}

.web-salah tr{
  height: 48px !important;
}

.web-home-page{
  margin-top: -30px;
}

.web-pages table{
  width: 100%;
}

.helper_text span span{
  font-size: 12px !important;
  margin-top: 4px;
}

.top_spacing{
  margin-top: 20px !important;
}

.form_body{
  padding:
  30px;
}

.page_switch{
  border-top: #E8E9E9 1px solid;
  padding-top: 20px;
}

.DrawerInfo{
  margin-top: 40px;
  color: #4D4D4D;
  font-size: 14px;
}

.DrawerInfo .DrawerInfoTitle{
  font-size: 12px;
  color: #818E94;
  margin-bottom: 10px;
}

.DrawerInfo a{
  font-size: 14px;
  color: #2490F9;
  text-decoration: none;
}

.ManageSalahField div:nth-child(3){
  visibility: hidden;
  display: none;
}

.ManageSalahField  div div:nth-child(2) div{
  visibility: hidden;
  display: none;
}

.ManageSalahField div div:first-child div{
  visibility: visible;
  display: -webkit-flex;
  display: flex;
}



.SteperContainer {
    padding-top: 31px;
}

.SteperContainer .MuiStepper-root {
    width: 50% !important;
}

.SteperContainer .MuiStepConnector-root {
    width: auto !important;
}


.StepperFormContainer {
    border-top: #D3D8DA 1px dashed;
    max-width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 100px;
}

.org_logo img {
    width: 24px;
    margin-right: 8px;

}

.org_logo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.enablePlan {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.enablePlan svg {
    font-size: 24px;
    margin-right: 8px;
    color: #2490F9;

}

.disablePlan {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.disablePlan svg {
    font-size: 24px;
    margin-right: 8px;
    color: #A4A7B0;

}


.Accr_Body {
    margin-left: 33%;
}

.StepperFormContainer .MuiAccordionDetails-root {
    display: block;
    padding: 8px 16px 16px;
}

.stepperTitle {
    color: #5A5A5A !important;
    font-weight: 300 !important;
}

.ModalForm .MuiOutlinedInput-adornedEnd {
    padding-right: 14px;
    width: 100%;
}

.formInfoSection {
    display: -webkit-flex;
    display: flex;
    color: #818E94;
    font-size: 14px;
    line-height: 20px;
}

.formInfoSection p {
    color: #818E94 !important;
    font-size: 14px !important;
    line-height: 23px !important;
}

.formInfoSection svg {
    color: #A4A7B0;
    margin-right: 8px;
}

.ModalFour .ModalFourMdlBody {
    padding: 32px 30px;
    overflow-y: auto;
}

.ModalFour .SelectFieldOne .MuiSelect-selectMenu {
    height: 48px;
}

.ModalFour .input_container .MuiOutlinedInput-input {
    height: 48px;
}

.OnboardFormButton {
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 4px #0000001A;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: end;
            justify-content: end;
    padding: 20px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
}

.OnboardFormButtonCtn {
    padding-right: 7%;
    display: -webkit-flex;
    display: flex;
}

.OrgypeField div {
    min-width: 100%;
}

.DrwrBottominfo {
    border-top: #D3D8DA dashed 1px;
    margin-top: 40px;
    padding-top: 30px;

}

.DrwrBottominfo p {
    color: #818E94;
    font-size: 12px;
}

.DrwrBottominfo h2 {
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400 !important;
}



.DrwrBottominfo h2 a {
    color: #2A82F6;
    font-size: 14px;
    text-decoration: none;

}

.cardDrawer .MuiListItemSecondaryAction-root {
    right: 24px;
}

.acdAcord .MuiButtonBase-root {
    display: -webkit-flex !important;
    display: flex !important;
}

.acdAcord .MuiAccordionSummary-expandIcon.Mui-expanded {
    -webkit-transform: none !important;
            transform: none !important;
    -webkit-transform: initial !important;
            transform: initial !important;
}


.field_boxAcord {
    width: 61%;
    position: relative;
    top: 0;
    left: 0;
}

.StepperFormCtn {
    max-width: 50%;
    margin: 0 auto;
}

.MuiFormControl-root {
    width: 100% !important;
}

.DateRageGrey .MuiPaper-root {
    background-color: transparent !important;
}

.DrwrInfoSection {
    position: absolute;
    bottom: 170px;
    max-width: 320px;
}

.DrwrInfoSection p {
    font-size: 14px;
    color: #4D4D4D;
    font-weight: 400;
    line-height: 22px;
}

.DrwrInfoSection a {
    font-size: 14px;
    color: #2A82F6;
    font-weight: 400;
    line-height: 22px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
}

.DrwrInfoSection a svg {
    font-size: 22px;
    margin-right: 4px;
}

.backbtnLeft .backBtn {
    position: relative;
    left: 0 !important;
    top: 0 !important;
    margin-top: 0 !important;
}

.title_top.LeftTitle {
    text-align: left !important;
    margin-top: 30px !important;
}

.TimeFieldContainer .MuiInputBase-root {
    border: none !important;
    border-bottom: 1px solid #ced6de !important;
    border-radius: 4px;
    height: 56px;
    padding: 0 !important;
}

.FixedBtnSection{
    background: #fff;
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 0px 0px;
    left: 0;
    box-shadow: 0px -1px 4px #0000001A;
}

.FixedBtnSection .form_button {
    position: relative;
    right: 80px;
}

 .stripe-para {
  font-size: 16px;
  color: #818e94;
  letter-spacing: 0.48px;
  text-align: center;
  line-height: 25px;
  margin: 0;
}

.strip_title{
  letter-spacing: 0.48px;
  color: #4D4D4D;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 21px;
  text-align: center;
}

.setup-footer {
  margin-left: auto;
  position: fixed;
  bottom: 0;
  left: 0%;
  width: 100%;
}

.MuiStepIcon-root {
  font-size: 32px !important;
}

.stripe-account {
  text-align: center;
  padding-bottom: 10px;
}

.stripe-skip-link {
  display: inline-block;
  background: #F7F8FA;
  border: #E8E9E9 1px solid;
  padding: 12px 28px;
  text-decoration: none;
  color: #4D4D4D;
  font-weight: 500;
  border-radius: 4px;
}

.creatButn{
  text-align: center;
  margin-top: 10px;
}

.stripe-skip{
  text-align: center;
  margin: 0;
}

.stripe-skip a {
  text-align: center;
  text-transform: uppercase;
  color: #818E94;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}


.main-footer{
  
}
#main-footer-border{
  border-top: 1px solid rgba(129, 142, 148, 0.3) !important;
}

.footer-secured {
  text-align: left;
  letter-spacing: 0.4px;
  color: #818e94;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-flex: 1 1;
          flex: 1 1;
  opacity: 0.7;
}

.footer-terms {
  text-align: right;
  letter-spacing: 0.4px;
  color: #818e94;
  text-transform: uppercase;
  font-size: 12px;
  margin-right: 20px;
  opacity: 0.7;
  cursor: pointer;
}

.footer-terms a {
  color: #818e94;
  text-decoration: none;
}

.footer-copyright {
  text-align: right;
  letter-spacing: 0.4px;
  color: #818e94;
  text-transform: uppercase;
  font-size: 12px;
  opacity: 0.7;
}
.main-footer svg{
  margin-bottom: 0px !important;
}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header{
	padding: 20px;
	background: #fff;
	text-align: center;
	box-shadow: 0px 10px 18px -19px rgba(0,0,0,0.75);
}

.logo_main{
	width: 300px;
}
/* .footer{
	text-align: center;
} */

.btm_ftr_left {
    margin-top: 18px;
    width: 50%;
    float: left;
}

.copyright_links {
    text-align: right;
    color: #6f7d84;
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
    float: right;
}
/* 
.footer {
    background: #f7f8fa;
    padding: 60px;
} */

.ftr_btm {
    background: #f7f8fa;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: #d1d7d9 1px solid;
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* .footer p {
    font-size: 18px;
    color: #4C5E67;
    max-width: 824px;
    line-height: 35px;
    letter-spacing: 1px;
    display: block;
    margin: 0 auto;
} */

.copyright_links img {
    width: 133px;
    margin-left: 10px;
}

.ftr_logo {
    max-width: 255px;
    margin-bottom: 20px;
}

.btm_ftr_left p {
    color: #4C5E67;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
.btm_ftr_left a {
    color: #4C5E67;
    font-size: 12px;
    font-weight: 400;
}

.copyright_links {
    text-align: right;
    color: #6f7d84;
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
    float: right;
}

@media (max-width:990px){
    .btm_ftr_left {
        margin-top: 18px;
        width: 100%;
        float: none;
        text-align: center;
    }

    .copyright_links {
        text-align: center;
        color: #6f7d84;
        font-size: 14px;
        font-weight: 400;
        padding-top: 20px;
        float: none;
        width: 100%;
    }

    .ftr_btm {
        position:relative;
    }
}
body{
	/* background: url(../img/dark_pattern.png); */
}

.page_title {
    padding: 20px;
    text-align: center;
    max-width: 1125px;
    margin: 0 auto;
    padding-top: 12%;
}

.page_title h1 {
    font-size: 36px;
    font-weight: 600;
    margin-top: 0;
    color: #ffffff;
}
.content_section{
	padding-top: 40px;
	padding-bottom: 40px;
}
.preselect-amount {
  height: 46px;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-right: 5px;
  position: relative;
  margin-left: 5px;
}
.preselect-amount input {
  height: 11px;
}
.preselect-amount .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2a82f6 !important;
}
.preselect-amount input {
  color: #4d4d4d !important;
  caret-color: transparent;
}
.preselect-amount div div:hover {
  border-color: none !important;
}

/* tool tip */
.preselect-amount .tooltiptext {
  border-radius: 2px;
  width: 114px;
  text-align: center;
  border-radius: 6px;
  padding: 13px 0px;
  position: absolute;
  z-index: 1;
  top: 128%;
  left: 50%;
  margin-left: -60px;
  border: 1px solid #e8e9e9;
  letter-spacing: 1.25px;
  color: #2a82f6;
  text-transform: uppercase;
  opacity: 1;
  background: white;
  font-size: 13px;
  font-weight: 500;
  box-shadow: -6px 3px 7px #E8E9EA, 2px 0px 5px #e8e9e9;
  font-family: "Roboto", sans-serif !important;
  transition: all .1s ease;
}
.preselect-amount .tooltiptext:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border: 10px solid transparent;
  border-bottom-color: #e8e9e9;
}
.preselect-amount .tooltiptext:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border: 10px solid transparent;
  border-bottom-color: white;
}
.preselect-amount:hover .tooltiptext {
  visibility: visible;
}

.skeletons {
    margin-top: 30px;
}
.skeletons div {
  display : -webkit-flex;
  display : flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.skeletons span {
  margin-bottom: 20px;
}
.react-loading-skeleton {
  background-color: #eee !important;
  background-image: linear-gradient( 90deg,#eee,#f5f5f5,#eee ) !important;
}
.active fieldset {
  border-color: #2490f9 !important ;
}
.main-form-container {

}

.df-header-setup-page {
  width: 570px;
  height: 320px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  /* border:1px solid black; */
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.alignmentTableonCategory {
  width: 90% !important;
  margin-left: 6rem !important;
}

.df-donation-form-setup-cards {
  width: 570px;
  height: 205px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}

.df-colors {
  width: 570px;
  height: 105px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-donation-amounts {
  width: 570px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
  padding-bottom: 20px;
}

.df-donation-embed {
  width: 570px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-setup-colorpicker {
  width: 100% !important;
  background: rgb(255, 255, 255);
  border: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  position: relative;
}

div.twitter-picker.df-setup-colorpicker > div:nth-child(1) {
  display: none !important;
}

.df-embed-colorpicker {
  background: rgb(255, 255, 255);
  border: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  position: relative;
  margin-bottom: 25px;
}
.hitInput > div div:nth-child(7) {
  position: absolute;
  top: 36px;
}
.hitInput > div > div > input {
  width: 75% !important;
  margin-top: 10px !important;
  margin-left: 30px;
}
.hitInput > div > div {
  margin-top: 10px !important;
}

div.twitter-picker.df-embed-colorpicker.hitInput.draw_btn_color > div:nth-child(1) {
  display: none !important;
}


.header-logo {
  width: 570px;
  height: 133px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.emb_card {
  width: 570px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 6px #f3f3f3;
  margin-top: 10px;
  padding: 20px;
}
.header-logo-2 {
  width: 570px;
  height: 230px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-header-accounts {
  width: 570px;
  height: 170px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-header-homepage {
  width: 570px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
  padding: 9px 0px;
}
.df-header-headline {
  width: 570px;
  height: 130px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}

.df-header-editor {
  width: 570px;
  height: 296px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}

.card_title{
  padding-bottom: 17px;
}

.twitter-picker.df-setup-colorpicker div {
  padding-left: 0 !important;
}

.df-form-title {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  text-transform: uppercase;
  font-size: 14px;
  padding: 0;
  margin: 0;

}
.df-donation-form-cards {
  margin-left: -8px;
}

#root > div > div > section > section > section > section > section > div.main-form-container > section > div:nth-child(1) > div.df-donation-form-cards > div:nth-child(1) > button > span.MuiButton-label > div > button > div {
  border: 1px solid #2A82F6;
}

/* #2A82F6 */

.Avatar .MuiCardContent-root {
  padding: 8px;
  border: 1px solid #D3D8DA;
  border-radius: 3px;
}

.selected-card .Avatar .MuiCardContent-root {
  padding: 8px;
  border: 1px solid #2A82F6;
  border-radius: 3px;
}

.df-form-link {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.df-form-link-2 {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  font-size: 14px;
  margin: 0;
}

.df-form-link-3 {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  font-size: 16px;
  padding: 15px 0 0px 0px;
  margin: 0;
}

.df-categories-dummy-text {
  color: var(--unnamed-color-818e94);
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0.4px;
  color: #818e94;
}
.df-categories-link-text {
  color: var(--unnamed-color-2a82f6);
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0.4px;
  color: #2a82f6;
}
.df-form-footer-link {
  letter-spacing: 1.25px;
  color: #4d4d4d;
  font-size: 14px;
  margin: 0;
  font-weight: bold;
}
.df-form-btn {
  cursor: pointer;
  background: #f7f8fa 0% 0% no-repeat padding-box !;
  border: 1.5px dashed #e8e9e9;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #F7F8FA;
}
.df-dr-vd-dexc {
  color: var(--unnamed-color-4d4d4d);
  text-align: left;
  width: 260px;
  font: normal normal normal 14px/24px Roboto;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
}
.df-form-btn-img {
  border: 1.5px dashed #efefef;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px;
}
.df-form-btn-img img {
  width: 200px;
  object-fit: contain;
  height: 60px;
}
.df-form-link-field {
  margin-left: -10px;
  margin-top: 5px;
}
.df-form-link-icon span {
  letter-spacing: 0.4px;
  color: #4d4d4d;
  opacity: 1;
  font-size: 12px;
  padding-left: 2px;
  padding-bottom: 2px;
}
.df-form-link-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 12px;
}
.df-form-link-field input {
  height: 0px;
  background: white;
}
.TabsButtonStyling .tab_header_button {
  margin-right: 45px;
}
.df-form-link-box {
  background-color: #f7f8fa !important;
  border-radius: 4px;
  position: relative;
  display: -webkit-flex;
  display: flex;
}

.df-form-link-box .MuiTooltip-tooltipArrow {
  padding: 10px !important;
  color: #ffffff ;
}

/* MuiTooltip-arrow */

.df-form-btn-text {
  text-align: center;
  text-transform: uppercase;
  color: #2a82f6;
  font-size: 14px;
  font-weight: 500;
}

.df-form-para {
  letter-spacing: 0.13px;
  color: #818e94;
  font-size: 14px;
  margin: 0;
  padding-top: 7px;
}
.df-form-subtitle-para {
  letter-spacing: 0.13px;
  color: #818e94;
  font-size: 14px;
  margin: 0;
}
.df-form-link-text {
  letter-spacing: 0.15px;
  background-color: #f7f8fa !important;
  color: #bcc0c2;
  font-size: 16px;
  margin: 0;
  padding: 14px 14px 16px 13px;
}
.df-form-link-text-field {
  letter-spacing: 0.15px;
  font-size: 16px;
  margin: 0;
  /* padding: 14px 0 16px 13px; */
}
.df-link-copy {
  letter-spacing: 1.25px;
  color: #2a82f6;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 3px;
  font-weight: 500;
}

.df-copy-div .MuiTooltip-popper .MuiTooltip-tooltip, 
.df-form-link-box .MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #818E94 !important;
  padding: 10px !important;
  position: relative !important;
}

.df-form-link-box .MuiTooltip-popper {
  left: 0px !important;
  top: -15px !important;
}

.df-copy-div .MuiTooltip-popper {
  left: -10px !important;
  top: -15px !important;
}

.df-copy-div .MuiTooltip-arrow,
.df-form-link-box .MuiTooltip-arrow {
  position: absolute !important;
  left: 50% !important;
  bottom: 1px !important;
-webkit-transform: translateX(-80%) !important;
transform: translateX(-80%) !important;
}

.df-homepage-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.df-home-logo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: auto;
  padding-top: 10px
}


.df-homepage-btn  svg{
  position: relative;
  left: -4px;
  top: 2px;
}

.df-switch {
  padding-top: 0;
  padding-right: 0;
}

.df-text-field {

}

.df-header-headline .df-text-field {
  padding: 0 20px 0px 20px;
}

.df-donation-form-cards .DrawerCard .MuiPaper-outlined {
  width: 278px !important;
  padding-left: 0px !important;
  border: none;
}

.df-donation-form-cards .MuiPaper-outlined{
  border: none !important;
}


.df-donation-form-cards .MuiButton-text {
  
}

.df-form-para-amounts {
  letter-spacing: 0.15px;
  color: #4d4d4d;
  font-weight: bold;
  font-size: 16px;
}
.onActive {
  width: 121px;
  height: 46px;
  cursor: pointer;
  border: 2px solid var(--unnamed-color-2a82f6);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #2a82f6;
  border-radius: 4px;
  margin-right: 10px;
  opacity: 1;
}
.df-amount {
  width: 121px;
  height: 46px;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e9e9;
  border-radius: 4px;
  margin-right: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.df-form-amount-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-left: -5px;
  margin-right: -5px;
}

.df-price {
  padding: 14px 0 13px 16px;
  margin: 0;
}

.df-divider {
  padding: 20px 0;
  padding-bottom: 10px;
}

.df-divider hr{
  width: auto !important;

}

.df-form-link-radio {
  display: -webkit-flex;
  display: flex;
}

.df-theme-color {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  margin-right: 10px;
}
.df-donation-form-colors {
  display: -webkit-flex;
  display: flex;
  padding-left: 20px;
}
.df-previewButton {
  width: 170px;
  height: 45px;
  background: #3cc480 0% 0% no-repeat padding-box;
  border-radius: 5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.df-custom-color {
  width: 194px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e9e9;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
}

.df-hash {
  width: 36px;
  height: 37px;
  background-color: rgb(240, 240, 240) !important;
  border: 1px solid #e8e9e9;
  border-radius: 4px 0px 0px 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.df-custom-color .df-custom-color-input {
  width: 138px;
  height: 36px;
  border: none;
  border-bottom: 1px solid #e8e9e9;
  color: rgb(102, 102, 102) !important;
  font-size: 14px;
}

.twitter-picker.df-embed-colorpicker.hitInput.draw_btn_color input {
  height: 36px !important;
  width: 200px !important;
  padding-left: 20px !important;
  margin-left: 38px;
}

div.twitter-picker.df-embed-colorpicker.hitInput.draw_btn_color > div:nth-child(3) > div:nth-child(7) {
  height: 38px !important;
  font-size: 15px !important;
  width: 42px !important;
} 

.df-custom-color .df-hash-sign {
  margin: 0;
  -webkit-align-self: center;
          align-self: center;
  background-color: rgb(240, 240, 240) !important;
  color: rgb(152, 161, 164) !important;
}

.df-img-text-field {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.df-img-text-field label{
  font-weight: 400;
}

.df-preview-btn {
  border-top: 1px solid #e8e9e9;
  border-bottom: 1px solid #e8e9e9;
  opacity: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 20px 0px 20px 0;
  margin: 20px 0;
}
.df-btn-preview-box {
  width: 170px;
  height: 45px;
  background: #3cc480 0% 0% no-repeat padding-box;
  border-radius: 23px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.df-btn-preview-text {
  color: white;
  text-transform: uppercase;
  text-align: center;
}

.df-code-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e9e9;
  border-radius: 4px;
  margin: 20px 0px;
}

.df-html-code p{
  line-height: 24px;
  font-size: 14px;
  color: #4D4D4D;
}

.df-html-code {
  padding: 20px 0px 35px 20px;
}

.df-copy-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.df-donation-pop-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  cursor: pointer;
}
.subHeaderDrawer {
  font-size: 14px !important;
}
/* .MuiPaper-outlined {
  border: none !important;
} */
.df-copy-text {
  letter-spacing: 1.25px;
  color: #4D4D4D;
  padding-right: 20px;
  text-transform: uppercase;
  cursor: pointer;
  display : block;
  position: relative;
  font-weight: 500;
}
.df-copy-text:after {
  content: "";
  background: #b2c7e4;
  display: block;
  position: absolute;
  padding-top: 24%;
  padding-left: 104%;
  margin-left: -1px !important;
  margin-top: -22%;
  opacity: 0;
  transition: all 0.5s;
}
.df-copy-text:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}
.df-upgrade-box {
  background: #edf6ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px;
}
.upgrade-text {
  letter-spacing: 0px;
  color: #4d4d4d;
  line-height: 1.6rem;
}

.df-upgrade-now {
  letter-spacing: 1.25px;
  color: #2a82f6;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

/* .df-text-field .text-editor div:nth-child(1) {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
} */
.df-form-box {
  width: 570px;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  box-shadow: 0 0 10px #eee;
  margin-top: 30px;
}
.df-form-para.note {
  letter-spacing: 0.4px;
  color: #b6b9b9;
  opacity: 1;
  font-size: 12px;
  margin-top: 6px;
}

.file_name{
  font-size: 16px;
  color: #4D4D4D;
  letter-spacing: 0.15px
}

.emb_card .MuiOutlinedInput-notchedOutline {
  border-color: #E8E9E9;
}

.min_donaton{
  margin-top: 15px;
}

.draw_btn_color div{
  padding: 0 !important;
}

.step_card_fild.df-donation-form-cards .DrawerCard .MuiPaper-outlined {
  width: 278px !important;
  padding-left: 0px !important;
  border: none !important;
}

.df-donation-form-cards .Avatar .MuiCardContent-root {
  padding: 8px;
  width: 278px;
}
.df-donation-form-cards{
  width: 105%;
}

.df-donation-form-cards .MuiCardActionArea-root {
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  text-align: inherit;
}

.tab_header_button {
  right: 80px !important;
  margin-top: 3px !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #3CC480 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #3CC480 !important;
}

.CopiedTooltip{
  display: none;
}

/* p.df-link-copy:focus .CopiedTooltip{
  display: block;
  position: absolute;
} */

.ZoneContainer{
    width: 80%;
}

.ChangeButton{
    margin-top: 16px;
}

.ChangeButton .MuiButtonBase-root {
    background-color: transparent !important;
    color: #ccc !important;
    border: #E4E8EC 1px solid;
    border-radius: 4px !important;
    width: 100%;
    padding: 0 !important;
    height: 34px !important;
    margin: 0 !important;
}

.ChangeButton .MuiButtonBase-root span{
    color: #4D4D4D;
}

.MuiTypography-gutterBottom{
    margin-bottom: 0 !important;
}

@media (max-width: 1746px) {
    .ZoneContainer{
        width: 79%;
    }
}

@media (max-width: 1600px) {
    .ZoneContainer{
        width: 77%;
    }
}

@media (max-width: 1500px) {
    .ZoneContainer{
        width: 75%;
    }
}

@media (max-width: 1400px) {
    .ZoneContainer{
        width: 72%;
    }
}

@media (max-width: 1300px) {
    .ZoneContainer{
        width: 70%;
    }
}
.CheckoutPaymentForm{
    border:#E4E8EC 1px solid;
    padding: 32px;
    border-radius: 4px;
}

.formSectionTitle{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color:#818E94;
    border-bottom:#E4E8EC 1px solid;
    padding-bottom:8px;
    text-transform: uppercase;
}

.mainTextHeading {
    color: #4D4D4D;
    font-size: 24px;
    margin-bottom: 5px;
}

.billing-info-wrapper .inner-form {
    width: 770px !important;
}

.separator_line{
    background-color: #E4E8EC;
    height: 1px;
}

.CheckoutPaymentForm .form_button_box{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
    -webkit-align-items: center;
            align-items: center;
}

.tos_text{
    color: #4C5E67;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.tos_text a{
    color: #0590FA;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.billingAddressContainer .billingAddressTitle{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.billingAddressContainer .billingAddressTitle span{
    color: #4C5E67;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.billingAddressContainer .billingAddressTitle a{
    color: #0590FA;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.billingaddressInfo li{
    display: block;
    color: #4C5E67;
    font-size: 16px;
    margin-top:4px;
    letter-spacing: 0px;

}


.masterCardImage {
    width: 40px !important;
    left: 5;
    position: 'relative';
}

/* .ReactFlagsSelect-module_selectBtn__19wW7 {
    font-size: 16px;
    margin-top: 0px !important;
    height: 56px;
} */

input {
    outline:none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
   margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.MuiList-root.MuiMenu-list .MuiListItem-gutters {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.form_button_Contianer .CheckoutRemovebutton .MuiButton-root {
    background-color: transparent !important;
}
.loading-para {
  color: var(--unnamed-color-818e94);
  text-align: center;
  letter-spacing: 0.48px;
  color: #818e94;
  margin-top: 40px;
}

.quote-div {
  margin-top: 137px;
}

.quote-text {
 
  text-align: center;
  letter-spacing: 0px;
  color: #4d4d4d;
  text-transform: capitalize;
  font-size: 28px;
}
.quote-book {
 
text-align: center;
font-size: 16px;
letter-spacing: 0px;
color: #818E94;
}
.signin_signInClass__3h7vH {
    width: 100% !important;
    margin: auto;
    border: unset;
    background-image: url(/static/media/khushbo_background.72bf7bb5.png);

}

.signin_signInClass__3h7vH div div div{
    border:none !important;
    background-repeat: no-repeat;  
}
 
.signin_signInClass__3h7vH div div div svg{
    top: 0;
}

.signin_signInClass__3h7vH section h1 {
    font-family: Roboto !important;
    color:#5A5A5A !important;
    font-weight: 400;
    font-size: 30px !important;
}

main section form section div:nth-child(2) label span:nth-child(2) p {
    font-family: Roboto !important;
    color:#D3D8DA !important;
    font-size: 14px !important;
}

.addSchduleBtn{
    color: #2490F9 !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
    cursor: pointer !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center !important;
            align-items: center !important;
    padding: 0  !important;
}

.addSchduleBtn:hover{
    background-color: transparent !important;
}

.addSchduleBtn svg{
    margin-right: 4px;
}

.addedField{
    position: relative;
}

.addedField .deleteField {
    padding: 0 !important;
    position: absolute;
    right: -50px;
    top: 21px;
    color: #818E94 !important;
}

.addedField .deleteField:hover {
    background-color: transparent !important;
    padding: 0 !important;
}

.Infoicon{
    padding: 0 !important;
    position: absolute;
    right: -40px;
    top: 21px;
    color: #818E94 !important;
    cursor: auto;
    cursor: initial;
}

.Infoicon_2 svg{
    color: #b1bfc5 !important;
    cursor: auto;
    cursor: initial;
    position: relative;
    top: 6px;
}

.AddParametersbutton {
    position: absolute;
    right: 0;
    top: -11px;
    color: #2490F9;
    font-size: 14px;
    line-height: 24px;
    background: #fff;
    border: #E4E8EC 1px solid;
    display: -webkit-flex;
    display: flex;
    padding: 2px 8px;
    border-radius: 4px;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.AddParametersbutton svg {
    font-size: 20px;
    margin-left: 5px;
}

.MuiStepLabel-active .stepLabel{
    font-weight: 600;
    color: #818E94;
}

.BorderField{

}



.BorderField div:before {
    font-weight: 400;
    font-size: 16px!important;
    padding: 0px 19px !important;
    height: 53px;
    color: #4d4d4d;
    border: 1px solid #e4e8ec;
    border-radius: 4px;
    background: transparent;
}

.BorderField div:after {
    font-weight: 400;
    font-size: 16px!important;
    padding: 0px 19px !important;
    height: 53px;
    color: #4d4d4d;
    border: 1px solid #e4e8ec;
    border-radius: 4px;
    background: transparent;
}
.SubsFormContainer{
    background:#F7F8FA;
    width: 100%;
    height: 100%;
    position: absolute;
    padding-top: 50px;
    padding-bottom: 50px;

}

.SubsFormContainer .QDCLogo{
    height: 50px;
    margin-bottom: 20px;
}
.SubsFormContainer .QDCLogo img{
   object-fit: contain;
   width: 100%;
   height: 100%;
}

.SubsFormBody{
    background: #fff;
    max-width: 600px;
    position: relative;
    margin: 0 auto;
    padding: 40px;

}

.SubsFormBody .SubsFormTitle{
    font-size: 18px;
    color: #444444;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 20px;
}

.SubsFormBody h3{
    font-size: 16px;
    color: #444444;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    line-height: 26px;
    margin-bottom: 30px;
    margin-top: 0;
}

.SubsFormBody p{
    font-size: 14px;
    color: #444444;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    line-height: 26px;
    margin-top: 0;
}

.SubsFormBody .SubsFormField{
    margin-top: 30px;
}

.SubsFormBody .SubsFormButton{
    margin-top: 30px;
}

.QDCFooterLogo{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 40px;
}

.QDCFooterLogo span{
    color: #A5A5A5;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 28px;
}

.QDCFooterLogo img{
    width:146px;
    margin-left: 4px;
}

.SubsFormBody .outlineButton .MuiButton-containedPrimary{
    background-color: #fff !important;
    border: #E4E8EC 1px solid;

}

.SubsFormBody .outlineButton .MuiButton-containedPrimary .button_text {
     color: #2490F9 !important;
}

.SubsFormBody .MuiButton-root{
    padding: 6px 16px !important;
}

.SubsFormBody .TextArea{
    margin-top: 30px;
}
.SubsFormBody .TextArea .lowerEditorService{
    position: absolute;
    right: 40px;
}
