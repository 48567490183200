.SteperContainer {
    padding-top: 31px;
}

.SteperContainer .MuiStepper-root {
    width: 50% !important;
}

.SteperContainer .MuiStepConnector-root {
    width: auto !important;
}


.StepperFormContainer {
    border-top: #D3D8DA 1px dashed;
    max-width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 100px;
}

.org_logo img {
    width: 24px;
    margin-right: 8px;

}

.org_logo {
    display: flex;
    align-items: center;
}

.enablePlan {
    display: flex;
    align-items: center;
}

.enablePlan svg {
    font-size: 24px;
    margin-right: 8px;
    color: #2490F9;

}

.disablePlan {
    display: flex;
    align-items: center;
}

.disablePlan svg {
    font-size: 24px;
    margin-right: 8px;
    color: #A4A7B0;

}


.Accr_Body {
    margin-left: 33%;
}

.StepperFormContainer .MuiAccordionDetails-root {
    display: block;
    padding: 8px 16px 16px;
}

.stepperTitle {
    color: #5A5A5A !important;
    font-weight: 300 !important;
}

.ModalForm .MuiOutlinedInput-adornedEnd {
    padding-right: 14px;
    width: 100%;
}

.formInfoSection {
    display: flex;
    color: #818E94;
    font-size: 14px;
    line-height: 20px;
}

.formInfoSection p {
    color: #818E94 !important;
    font-size: 14px !important;
    line-height: 23px !important;
}

.formInfoSection svg {
    color: #A4A7B0;
    margin-right: 8px;
}

.ModalFour .ModalFourMdlBody {
    padding: 32px 30px;
    overflow-y: auto;
}

.ModalFour .SelectFieldOne .MuiSelect-selectMenu {
    height: 48px;
}

.ModalFour .input_container .MuiOutlinedInput-input {
    height: 48px;
}

.OnboardFormButton {
    height: 48px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 4px #0000001A;
    display: flex;
    justify-content: end;
    padding: 20px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
}

.OnboardFormButtonCtn {
    padding-right: 7%;
    display: flex;
}

.OrgypeField div {
    min-width: 100%;
}

.DrwrBottominfo {
    border-top: #D3D8DA dashed 1px;
    margin-top: 40px;
    padding-top: 30px;

}

.DrwrBottominfo p {
    color: #818E94;
    font-size: 12px;
}

.DrwrBottominfo h2 {
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 400 !important;
}



.DrwrBottominfo h2 a {
    color: #2A82F6;
    font-size: 14px;
    text-decoration: none;

}

.cardDrawer .MuiListItemSecondaryAction-root {
    right: 24px;
}

.acdAcord .MuiButtonBase-root {
    display: flex !important;
}

.acdAcord .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: initial !important;
}


.field_boxAcord {
    width: 61%;
    position: relative;
    top: 0;
    left: 0;
}

.StepperFormCtn {
    max-width: 50%;
    margin: 0 auto;
}

.MuiFormControl-root {
    width: 100% !important;
}

.DateRageGrey .MuiPaper-root {
    background-color: transparent !important;
}

.DrwrInfoSection {
    position: absolute;
    bottom: 170px;
    max-width: 320px;
}

.DrwrInfoSection p {
    font-size: 14px;
    color: #4D4D4D;
    font-weight: 400;
    line-height: 22px;
}

.DrwrInfoSection a {
    font-size: 14px;
    color: #2A82F6;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.DrwrInfoSection a svg {
    font-size: 22px;
    margin-right: 4px;
}

.backbtnLeft .backBtn {
    position: relative;
    left: 0 !important;
    top: 0 !important;
    margin-top: 0 !important;
}

.title_top.LeftTitle {
    text-align: left !important;
    margin-top: 30px !important;
}

.TimeFieldContainer .MuiInputBase-root {
    border: none !important;
    border-bottom: 1px solid #ced6de !important;
    border-radius: 4px;
    height: 56px;
    padding: 0 !important;
}

.FixedBtnSection{
    background: #fff;
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 0px 0px;
    left: 0;
    box-shadow: 0px -1px 4px #0000001A;
}

.FixedBtnSection .form_button {
    position: relative;
    right: 80px;
}